.table {
    padding: 5px;
    width: 100%;
    overflow-x: auto;
  
    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 1.4rem;
  
      thead {
        border-top: 2px solid var(--light-blue);
        border-bottom: 2px solid var(--light-blue);
      }
  
      th {
        border: 1px solid #eee;
      }
  
      th,
      td {
        vertical-align: top;
        text-align: left;
        padding: 8px;
        &.icons {
          > * {
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
  
      tr {
        border-bottom: 1px solid #ccc;
        cursor: pointer;
      }
  
      tr:nth-child(even) {
        background-color: #eee;
      }
      .pending {
        color: orangered;
        font-weight: 500;
      }
      .delivered {
        color: green;
        font-weight: 500;
      }
    }
  }
  // Returns.module.scss

.statusDropdown {
  // Add your existing styles for the dropdown if any

  // Customize the button style
  button {
    background-color: #3498db; // Set your desired color
    color: #fff; // Set text color to white or a suitable contrast
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #2980b9; // Set a darker shade for hover effect
    }

    &:disabled {
      background-color: #bdc3c7; // Set a different color for disabled state
      cursor: not-allowed;
    }
  }

  button {
    // Styles for the update button
    background-color: #4caf50; /* Green color */
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  
    margin-left: 8px; // Adjust this value to add space around the button
      margin-right: 8px; // Adjust this value to add space around the button
  
  
    &:disabled {
      background-color: #cccccc; /* Grey color for disabled state */
      cursor: not-allowed;
    }
  }
}

