// CheckoutDetails.module.scss
.checkboxLabel {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.btn {
  margin-top: 20px;
  width: 60%;
  display: flex;
  justify-content: center; // Center the content horizontally
  align-items: center;
  margin-left: 83px;
  button {
    padding: 8px 12px;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #4caf50;
      color: white;
    }
  }
}
.copyCheckbox {
  margin-left: 0.5rem;
}
.checkout {
  width: 90%;
  position: relative;
  h2 {
    margin-bottom: 3rem;
  }

  .form {
    width: 100%;
    display: flex;

    .formSection {
      width: 100%;

      &:not(:last-child) {
        margin-right: 20px;
      }

      .card {
        padding: 25px 30px;
        width: 100%;
        max-width: 500px;

        h3 {
          font-weight: 400;
          margin-bottom: 2rem;
        }
      }

      .copyButton {
        width: 100%;
        margin-top: 1rem;
      }

      label {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      input[type="text"],
      .select,
      .card-details {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 0.5rem 0;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;
        outline: none;
        box-sizing: border-box;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .checkout {
    .form {
      flex-direction: column;

      .formSection {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}
