.filter {
  margin-left: 2rem;

  h4 {
    margin-bottom: 2rem;
  }

  .category {
    margin-bottom: 3rem;
    border-bottom: 2px solid #ccc;
    padding-bottom: 2rem;

    button {
      display: block;
      text-align: left;
      width: 100%;
      height: 3rem;
      font-size: 1.5rem;
      border: none;
      background-color: transparent;
      cursor: pointer;
      padding-left: 15px;
      transition: background-color 0.3s ease, transform 0.2s ease;
      transform-origin: left center;

      &:hover {
        background-color: #b9b6b6;
        transform: scale(1.05);
      }

      &.active {
        background-color: #a4d2fa;
      }
    }
  }

  .brand {
    margin-bottom: 3rem;
    border-bottom: 2px solid #ccc;
    margin-right: 3rem;
    padding-bottom: 3rem;

    select {
      font-size: 1.4rem;
      font-weight: 300;
      padding: 8px;
      width: 95%;
      border: 1px solid #777;
      border-radius: 3px;
      outline: none;
    }
  }

  .slider {
    width: 80%;
    margin-bottom: 2rem;
    border-bottom: 2px solid #ccc;
    margin-right: 3rem;
    padding-bottom: 3rem;
    padding-top: 1rem;

    input {
      // background: linear-gradient(
      //   to right,
      //   #10d5c2 0%,
      //   #10d5c2 calc((100% * 80) / 100),
      //   #ddd calc((100% * 80) / 100),
      //   #ddd 100%
      // );
      border-radius: 8px;
      height: 8px;
      width: 100%;
      border-radius: 4px;
      outline: none;
      transition: background 450ms ease-in;
      -webkit-appearance: none;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: url(https://raw.githubusercontent.com/milenig/fend-mentor_pricing-component/3ccfd6b18e87377a950915288ef64c896969bf62/images/icon-slider.svg)
          rgb(16, 213, 194) center no-repeat;
        background-size: 12px;
        box-shadow: 0px 10px 20px 0px rgba(16, 213, 194, 0.7);
        cursor: pointer;
        position: relative;
        transition: all 0.2s;

        &:hover {
          background: url(https://raw.githubusercontent.com/milenig/fend-mentor_pricing-component/3ccfd6b18e87377a950915288ef64c896969bf62/images/icon-slider.svg)
            rgb(27, 230, 209) center no-repeat;
          background-size: 12px;
        }

        &:active {
          cursor: grabbing;
          background: url(https://raw.githubusercontent.com/milenig/fend-mentor_pricing-component/3ccfd6b18e87377a950915288ef64c896969bf62/images/icon-slider.svg)
            rgb(13, 190, 173) center no-repeat;
          background-size: 12px;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .filter {
      .category button {
        width: 100%;
      }

      .brand {
        select {
          width: 100%;
        }
      }
    }
  }

  .clearButton {
    background-color: var(--color-danger);
    color: white;
    font-size: 1.5rem;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    margin-right: 7rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #ff6666;
    }
  }
}
@media screen and (min-width: 1101px) and (max-width: 1175px) {
  .filter {
    .category {
      button {
        font-size: 1.4rem;
      }
    }
  }
}
@media screen and (min-width: 1056px) and (max-width: 1100px) {
  .filter {
    .category {
      button {
        padding-left: 0px;
      }
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 1055px) {
  .filter {
    .category {
      button {
        font-size: 1.3rem;
        padding-left: 0px;
      }
    }
  }
}
/* Add this style */
.activeSubcategory {
  background-color: #10d5c2; /* Change the background color to your preferred highlight color */
  color: #fff; /* Change the text color to make it readable */
}
