.topCategories {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* By default, show one column */
  gap: 30px;
  padding-top: 30px;
}
.topSection {
  display: flex;
  flex-direction: column; // For smaller screens, stack the elements vertically
  align-items: center; // Center the items vertically
}

.toptext {
  h4 {
    padding-left: 0; // Remove left padding for smaller screens
    text-align: center; // Center the text
    margin-bottom: 10px; // Adjust margin for smaller screens
  }
}
@media (min-width: 768px) {
  .topCategories {
    grid-template-columns: repeat(2, 1fr); /* On tablets, show two columns */
  }
}

@media (min-width: 1024px) {
  .topCategories {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* On screens larger than tablets, show three columns */
  }
}

@media (min-width: 1440px) {
  .topCategories {
    grid-template-columns: repeat(
      3,
      1fr
    ); /* On screens larger than 13-inch, show four columns */
  }
  .catlist {
    margin: 10rem !important;
    margin-top: 6.5rem !important;
  }
  .toptext {
    h4 {
      padding-left: 15px !important;
      margin: -0.5rem !important;
      margin-bottom: 0rem !important;
    }
  }
}

.catlist {
  margin: 0rem;
  padding-left: 40px;
  padding-right: 40px;
}

.categoryItem {
  // display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.categoryImage {
  width: 100%; /* Make the image responsive */
  aspect-ratio: 1; /* Maintain aspect ratio */
  object-fit: cover;
}

.categoryTitle {
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.9rem;
}

.toptext {
  h4 {
    padding-left: 15px;
    margin: 6.5rem;
    margin-bottom: 0rem;
  }
}
