.jerseyCategory {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .imageList {
    margin-bottom: 30px;
  }
  
  .imageList h3 {
    color: #666;
    margin-bottom: 10px;
  }
  
  .imageList ul {
    list-style: none;
    padding: 0;
  }
  
  .imageList li {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .imageInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .imageInfo span {
    font-weight: bold;
    font-size: 16px;
  }
  
  .imageActions button {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .imageActions button:hover {
    background-color: #c9302c;
  }
  
  .imageList {
    margin-top: 10px;
  }
  
  .imageItem {
    display: inline-block;
    margin-right: 10px;
    position: relative;
  }
  
  .imageItem img {
    max-width: 100px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .imageItem button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 2px 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .imageItem button:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  input[type="file"] {
    display: block;
    margin: 20px 0;
  }
  
  button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #5cb85c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  button:hover:not(:disabled) {
    background-color: #4cae4c;
  }
  
  .loader {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-top: 2px solid #337ab7;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
