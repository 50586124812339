.table {
  padding: 5px;
  width: 100%;
  overflow-x: auto;

  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 1.4rem;

    thead {
      border-top: 2px solid var(--light-blue);
      border-bottom: 2px solid var(--light-blue);
    }

    th {
      border: 1px solid #eee;
    }

    th,
    td {
      vertical-align: top;
      text-align: left;
      padding: 8px;
      &.icons {
        > * {
          margin-right: 5px;
          cursor: pointer;
        }
      }
    }

    tr {
      border-bottom: 1px solid #ccc;
      cursor: pointer;
    }

    tr:nth-child(even) {
      background-color: #eee;
    }
    .pending {
      color: orangered;
      font-weight: 500;
    }
    .delivered {
      color: green;
      font-weight: 500;
    }
  }
}
.order {
  padding: 0 80px;
}
.linkstyle {
  color: #007185; 
  font-size: 14px;
  cursor: pointer; 
}

.linkstyle:hover {
  text-decoration: underline;
  color: orangered; 
}
.orderList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(75%, 1fr)); // Set 75% width
}
.headerDiv {
  background-color: #f0f2f2; /* Set the background color to #F0F2F2 */
  padding: 15px 15px 5px 15px; /* Add padding to the header */
  border-radius: 8px;
}
.orderItem {
  border: 1px solid #ddd;

  transition: transform 0.2s ease-in-out;
  width: 85%; /* Set the width to 75% */

  margin: 10px auto;

  .orderHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .orderFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  

  .orderDate1 {
    font-size: 14px;
    color: #555;
    font-weight: 600;
    margin-left: 0.5rem;
  }  .orderDate {
    font-size: 14px;
    color: #555;
  }

  .orderID {
    font-size: 14px;
    color: #555;
  }
  .itemDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .orderDetails {
    display: flex;
    flex-direction: column;
    padding: 20px;
    .orderAmount {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .orderStatus {
      font-size: 16px;
      font-weight: 500;

      &.delivered {
        color: green;
      }

      &.pending {
        color: orangered;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .order{
    padding: 0px;
  }
  .orderdiv{
    padding: 0px 35px !important;
  }
  .table{
    padding: 0;
  }
  .orderStatus{
    font-size: 13px !important;
  }
  .orderh4{
    padding: 0px !important;
  }
}