body.no-scroll {
  overflow: hidden;
}
.fixed {
  width: 100%;
  top: 0;
  transition: all 0.5s;
  z-index: 1000;
}.gradientButton {
    background: linear-gradient(45deg, orangered, #FAD0C4);
    border: none;
    font-weight: 600;
    border-radius: 20px;
    color: black;
    padding: 10px 20px;
    font-size: 10px;
    margin-right: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.gradientButton:hover {
    background: linear-gradient(45deg, #FAD0C4, orangered);
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  // display: flex;
  align-items: center;
  justify-content: center;
  input[type="text"] {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    font-size: 16px;
    border: none;
  }

  button {
    padding: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 10px;
  }
  .productItem {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
  }

  .productItem:last-child {
    margin-right: 0;
  }

  .productItem img {
    max-width: 100%;
    height: auto;
  }

  .productItem h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .productItem p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .productItem button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .productItem button:hover {
    background-color: #0056b3;
  }
}
.productsLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.productsLine p {
  margin: 0;
}

.productsLine a {
  text-decoration: none;
  color: #000;
}
.overlayContent {
  background-color: white;
  padding: 20px;
  // border-radius: 8px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.searchContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* Update the CSS for the container holding the product items */
.filteredProducts {
  display: flex;
  flex-wrap: wrap; /* Allow products to wrap to the next row */
  justify-content: space-between; /* Distribute products evenly along the main axis */
}

.categories {
  display: flex;
  flex-wrap: wrap;
  padding-left: 35px;
  margin-right: -16px;
}

.startNewRow {
}

.columnGap {
  margin-left: 10px; /* Adjust margin-left as needed for column spacing */
}

/* ... (existing styles) */

.categoryRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.subcategorySelect {
  width: 100%;
  font-size: 1rem;

  .custom-dropdown-menu {
    width: max-content;
    position: absolute;
    z-index: 9999; /* Adjust the z-index as needed */
  }

  .custom-dropdown-input {
    width: 100%;
  }

  a {
    text-decoration: none;
    color: white;

    &:hover {
      color: orangered;
    }
  }
}

header {
  .header {
    width: 100%;
    background-color: #0a1930;
    color: #fff;
    width: 100%;
    height: 8rem;
    // max-width: 1000px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .logo {
    width: 250px;

    img {
      width: 100%; /* Set to 100% to match the width of the image */
      max-height: 40%;
      display: block;
    }
  }

  nav {
    width: 35%;
    display: flex;
    justify-content: space-between;
    padding-right: 70px;

    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;

      .logo-mobile {
        display: none;
      }

      li {
        margin: 0 5px;
        a {
          color: black;
          &:hover {
            color: orangered;
          }
        }
      }
    }
  }

  .header-right {
    display: flex;
    margin-top: 7px;

    .cart a {
      display: flex;
      color: black;
      position: relative;
      margin-left: 6px;
      &:hover {
        color: orangered;
      }
      .icon {
      }
      &.active {
        color: var(--color-danger);
      }
      p {
        position: absolute;
        top: -1rem;
        right: -0.9rem;
        font-weight: 500;
      }
    }

    .wishlist a {
      display: flex;
      color: #fff;
      position: relative;
      &:hover {
        color: orangered;
      }
      &.active {
        color: var(--color-danger);
      }
      p {
        position: absolute;
        top: -1rem;
        right: -0.5rem;

        font-weight: 500;
      }
    }

    span {
      margin: 0 2px;

      p {
        color: white;
      }
    }

    .links a {
      margin: 0 2px;
      font-size: 16px;
      color: white;
      font-weight: 500;
      margin-right: 8px;
      &:hover {
        color: orangered;
      }
      &.active {
        color: var(--color-danger);
      }
    }
  }

  .menu-icon {
    cursor: pointer;
    display: none;
  }
.navOverlay {
  position: relative; /* Set position relative */
}

.overlay {
  position: fixed; /* Position the overlay fixed to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 99; /* Ensure the overlay is behind the navigation menu */
}

  @media screen and (max-width: 800px) {
    nav {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100vh;
      background-color: var(--dark-blue);
      padding: 1rem;
      z-index: 999;
      transform: translateX(-200%);
      transition: all 0.3s;
      background-color: #f4f4f4;
overflow-y: auto;
overflow-x: hidden;
      .nav-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        transform: translateX();
        transition: all 0.3s;
      }

      .show-nav-wrapper {
        transform: translateX(100%);
        width: 40vw;
        z-index: inherit;
      }
      html.show-nav-wrapper {
        overflow-x: hidden;
      }

      ul {
        display: block;
        .logo-mobile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > * {
            cursor: pointer;
          }
        }
        li {
          padding: 5px 0;
          border-bottom: 1px solid #ccc;
          a {
            display: block;
          }
        }
      }

      .header-right {
        display: block;
        .cart {
          // display: block;
          // padding: 5px 0;
          // border-bottom: 1px solid #333;
          // a {
          //   position: relative;
          //   p {
          //     position: absolute;
          //     top: -1rem;
          //     left: 5rem;
          //     font-weight: 500;
          //   }
          // }
        }

        .links {
          display: block;
          a {
            display: block;
            margin: 0;
            padding: 15px 0;
            margin-right: 4px;
            // border-bottom: 1px solid #333;
          }
        }
      }
    }


    .cart {
      display: block;
      padding: 5px 0;
      // border-bottom: 1px solid #333;
      a {
        color: #fff;
        position: relative;
        &:hover {
          color: orangered;
        }
        p {
          position: absolute;
          top: -1rem;
          // left: 2.5rem;
          font-weight: 500;
          // color: #fff;
        }
      }
    }
    .show-nav {
      transform: translateX(0);
      background: #0a1930;
    }
    .hide-nav {
      transform: translateX(-200%);
    }
    .menu-icon {
      display: flex;
      color: #3d3c3ca2;
      > * {
        margin-left: 1rem;
      }
    }
  }
}

.active {
  position: relative;
  color: var(--color-danger);
}

.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background-color: #fff;
}
.sportsBand {
  background-color: var(--dark-blue);
  color: #fff;
  padding: 1rem;
  overflow-x: auto; /* Add this property to enable horizontal scrolling if needed */
  padding-top: 10px; /* Optional: Add padding for better visual appearance */
  border-top: 0.1px solid #ccc;
  border-bottom: 0.1px solid #ccc;
  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;

    li {
      margin-right: 10px; /* Adjust the margin as needed */

      a {
        color: #fff;
        text-decoration: none;
        padding: 0.5rem;
        border-radius: 5px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}
.notificationContainer {
  position: relative;
}

.notificationDropdown {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  width: 200px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 300px;
  height: auto; /* Set height to auto for dynamic adjustment */
  overflow-y: auto; /* Add scrollbar if content overflows vertically */
  max-height: 300px; /* Set a maximum height for the dropdown */
  overflow-y: auto; /* Center horizontally */
  p {
    color: black !important;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
  }
}
.notificationCard {
  border: 1px solid #ddd; /* Add a border between notification cards */
  padding: 10px;
  margin: 5px 0;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  transition: background-color 0.3s ease;
}

.notificationCard:hover {
  background-color: #e0e0e0;
}

.boldNotification {
  font-weight: bold;
}

.clearNotifications {
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  color: #007bff;
}

.clearNotifications:hover {
  text-decoration: underline;
}
.notifications {
  display: flex;
  color: #fff;
  position: relative;
  cursor: pointer;
}

.notifications.active {
  color: var(--color-danger);
}

.notifications p {
  position: absolute;
  top: -1rem;
  right: -1rem;
  font-weight: 500;
}

/* Show dropdown when either the notificationContainer or notificationDropdown is hovered */
.notificationDropdown {
  display: block;
}

.searchdown {
  border-top: 1px solid #ccc;
  display: none;
}
@media screen and (max-width: 800px) {
  .searchContainer {
    width: 350px;
  }
  .overlayContent {
    width: 100vw;
  }
  /* Adjust styles for screens with a maximum width of 800px */
  header {
    .header {
      padding-right: 10px;
    }
    .iconsWrapper {
      border-bottom: "1px solid #ccc";
      padding-bottom: "15px";
    }
    nav {
      width: 60%;
    }
    .search {
      display: none;
    }
    .logo img {
      max-height: 30%;
    }
    .logomenu {
      max-width: 200px;
      img {
        width: 180px;
      }
    }
    .searchdown {
      display: block;
      width: 100%;
      display: flex;
      justify-content: center;
      .searchbar {
        width: 350px;
      }
    }
    /* Hide other nav items on mobile */
    .header-right {
      display: none;
    }
    .iconsWrapper {
      display: flex;
      align-items: center;
      margin-top: 25px;
      justify-content: center;
      .cart {
        // Adjust the margin as needed
        display: flex;
        align-items: center;
        top: -1rem;
        right: -1rem;
      }
      .notifications,
      .wishlist {
        display: flex;
        align-items: center;
      }
    }
    .menu-icon {
      display: none;
      display: flex;
      > * {
        margin-left: 1rem;
      }
    }
  }
}
.iconsWrapper {
  display: flex;
  align-items: center;

  .notifications,
  .wishlist,
  .cart {
    margin-right: 10px; // Adjust the margin as needed
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 500px) {
  .productItem {
    width: 100%; /* Set width for small screens */
  }
  .productsContainer {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: space-between; /* Distribute items evenly along the main axis */
    margin-top: 20px;
  }

  /* Adjust individual product item width based on screen size */
  .productItem {
    width: calc(33.33% - 10px); /* Set initial width for large screens */
    margin-bottom: 20px; /* Add space between product items */
  }
}
