// ReviewProducts.module.scss
.reviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.removeImageButton {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c82333;
  }
}

.customFileLabel {
  cursor: pointer;
  padding: 1rem;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 1rem;
}

.imageUploadContainer {
  margin-bottom: 1rem;
}

.uploadedImagesContainer {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.uploadedImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}


.reviewHeading {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
}

.productDetails {
  text-align: center;

  p {
    font-weight: bold;
    margin-bottom: 1rem;

    b {
      margin-right: 0.5rem;
    }
  }
}

.productImage {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.spinner {
  width: 50px;
}

.card {
  width: 100%;
  max-width: 1000px;
  padding: 1rem;

  form {
    display: flex;
    flex-direction: column;

    .label {
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      color: #333;
    }

    .textarea {
      font-size: 1.6rem;
      padding: 1rem;
      margin-bottom: 1rem;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 5px;
      outline: none;
    }

    .btn {
      &.btnPrimary {
        background-color: #007bff;
        color: #fff;
        padding: 1rem;
        font-size: 1.6rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #0056b3;
        }
      }
    }

    // New Styles for Image Upload
    .imageUploadContainer {
      margin-bottom: 1rem;

      .imageUpload {
        position: relative;

        input {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }

        .progress {
          margin-top: 0.5rem;
          height: 20px;
          background-color: #eee;
          border-radius: 5px;
          overflow: hidden;

          .progress-bar {
            height: 100%;
            background-color: #007bff;
            transition: width 0.3s ease;
          }
        }
      }
    }
    // End of New Styles for Image Upload
  }
}
