.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.returndiv {
  text-align: left;
}

.returnList {
  display: grid;
  grid-gap: 20px;
}

.returnItem {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
}

.returnHeader {
  margin-bottom: 10px;
}

.returnDateAndID {
  background-color: #e9ecef;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.returnDate,
.returnID {
  font-size: 16px;
}

.returnID {
  order: 1; /* Push order ID to the extreme right */
}

.returnDetails {
  margin-top: 10px;
}

.returnProduct,
.returnReason,
.returnStatus {
  font-size: 16px;
  margin-bottom: 5px;
}

.returnPending,
.returnStatus {
  color: orangered;
}

.returnDate1 {
  font-style: italic;
  font-size: 14px;
}
@media screen and (max-width: 800px) {
  .returndiv {
    padding: 0px 35px !important;
  }
}
