.card {
  margin-top: 20px;
  margin-bottom: 20px;
}
.gsingin {
  img {
    width: 320px;
  }
}
.auth {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .eye-icon {
    position: absolute;
    right: 10px; /* Adjust the right position as needed */
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    color: #777;
  }

  .visible {
    display: block;
  }

  .hidden {
    display: none;
  }

  .--btn-secondary {
    background-color: #6c757d;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }

  .--btn-small {
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }

  input {
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .img {
    animation: slide-down 0.5s ease;
    transform: translateY(-10%);
  }

  .form {
    width: 35rem;
    padding: 1.5rem;
    animation: slide-up 0.5s ease;

    h2 {
      color: var(--color-danger);
      text-align: center;
    }

    form {
      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="tel"],
      input[type="date"] {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 1rem auto;
        width: 100%;
        border: 1px solid #777;
        border-radius: 3px;
        outline: none;
      }

      .links {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
      }

      p {
        text-align: center;
        margin: 1rem;
      }
    }

    .register {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
    }
  }

  @keyframes slide-up {
    0% {
      transform: translateY(-5rem);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slide-down {
    0% {
      transform: translateY(5rem);
    }
    100% {
      transform: translateY(0);
    }
  }
}

@media screen and (max-width: 700px) {
  .img {
    display: none;
  }
}

// ...

.twoInputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .error {
    position: absolute;
    bottom: -1.5rem;
    color: red;
    font-size: 0.8rem;
  }

  .inputGroup {
    flex: 1;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
    input.invalidInput {
      border: 1px solid red !important;
    }
    input {
      display: block;
      font-size: 1.6rem;
      font-weight: 300;
      padding: 1rem;
      margin: 1rem auto;
      width: 100%;
      border: 1px solid #777;
      border-radius: 3px;
      outline: none;

      // &:required {
      //   border-color: red !important;
      // }

      &:valid {
        border-color: #777;
      }
    }
  }
}

// ...
// ...

.errorBorder {
  border-color: red !important;
}

// ...

.error {
  color: red;
  font-size: 0.8rem;
  margin-bottom: 5px;
}
.inputGroup {
  position: relative;
}

.twoInputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.inputGroup {
  flex: 1;
  margin-right: 10px;
  position: relative; /* Ensure the position is relative */
}

.required {
  color: red;
}

.linkstyle {
  color: #007185;
  font-size: 13px;
  cursor: pointer;
  padding-left: 5px;
}

.linkstyle:hover {
  text-decoration: underline;
  color: orangered;
}
