/* CheckoutSuccess.module.scss */

.container {
  text-align: center;
  margin-top: 50px;
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
}

.btn {
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    padding: 3px 12px;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 10px;
    width: 10%; /* Adjust the width as needed */
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #4caf50;
      color: white;
    }
  }
}
@media screen and (max-width: 800px) {
  .btn {
    button {
      width: 40%;
    }
  }
}
