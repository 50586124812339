.orderDetails {
  .tracking-url-link {
    color: #007bff; /* Blue color, you can adjust this */
    text-decoration: underline;
    cursor: pointer;
  
    :hover{
      color: orangered;
    }
  }

  padding: 20px;

  h2 {
    font-size: 28px;
    color: var(--darkblue);
    margin-bottom: 10px;
  }

  .backLink {
    color: var(--darkblue);
    text-decoration: none;
    font-size: 16px;

    &:hover {
      text-decoration: underline;
    }
  }

  .spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .spinner {
    width: 50px;
  }

  .orderInfo {
    margin-bottom: 20px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    p {
      margin: 8px 0;
      font-size: 17px;
      display: inline-block;

      b {
        font-weight: bold;
      }
    }
  }

  .tableWrapper {
    margin: 0px 50px;
    overflow-x: auto;
  }

  .orderTable {
    width: 100%;
    border-collapse: collapse;

    tr {
      th {
        font-size: 14px;
      }
    }

    th,
    td {
      border: 1px solid #ddd;
      padding: 12px;
    }

    th {
      background-color: #f2f2f2;
      color: var(--darkblue);
      font-size: 16px;
    }

    td {
      font-size: 14px;

      img {
        max-width: 80px;
        max-height: 80px;
        margin-top: 8px;
        border-radius: 4px;
      }
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }
  }

  .itemDetails {
    display: flex;
    margin-left: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    align-items: center;
  }

  .linkstyle {
    color: #007185;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: #ff4500;
    }
  }

  .itemInfo {
    margin-left: 10px;
    margin-right: 80px;
  }

  .prodImage {
    margin-right: 30px;
    width: 75px;
  }

  .prodname {
    position: relative;
    font-size: 18px;
    line-height: 32px;
    width: 340px;
    font-weight: 500;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 350px;
      height: 1px;
      background-color: #ccc;
    }

    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .priceSym {
    top: -0.5em;
    font-size: 13px;
    position: relative;
  }

  .priceData {
    font-size: 22px;
  }

  .tax {
    padding-left: 0.9rem;
    margin-top: -8px;
  }
}

.linkstyle {
  color: #007185;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: orangered;
  }
}

.customModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; /* Adjusted for better responsiveness */
  max-width: 600px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.customOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalContent h3 {
  margin-bottom: 20px;
}

.modalContent p {
  margin-bottom: 20px;
}

.modalContent label {
  display: block;
  margin-bottom: 10px;
  text-align: left;
}

.modalContent textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.modalContent button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
}

.modalContent button:last-child {
  background-color: #ccc;
  color: #333;
}

@media screen and (max-width: 800px) {
  .orderDetails {
    padding: 0;
  }

  .orderInfo {
    margin-left: 15px;
    margin-right: 15px;
    flex-direction: column;

    p {
      font-size: 16px;
    }
  }

  .tableWrapper {
    margin: 0px 15px;
  }
}
.modalContent select {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

/* Add this style for the custom reason textarea */
.modalContent textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

/* Add this style for the return quantity input */
.modalContent input[type="number"] {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

/* Add this style for the submit and cancel buttons */
.modalContent button {
  background-color: #007bff; /* Set your desired button background color */
  color: #fff; /* Set your desired button text color */
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
}

.modalContent button:last-child {
  background-color: #ccc; /* Set your desired cancel button background color */
  color: #333; /* Set your desired cancel button text color */
}

.customModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px; /* Set your desired width */
  max-width: 100%;
  padding: 20px;
  background-color: #f5f5f5; /* Set your desired background color */
  border: 1px solid #ccc; /* Set your desired border color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Set your desired box shadow */
  text-align: center; /* Center the content horizontally */
  border-radius: 8px; /* Set your desired border-radius */
}

/* Style for the return product modal overlay */
.customOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Set your desired overlay color */
}

/* Style for the modal content */
.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content vertically */
}

/* Style for the return product dropdown */
.modalContent select {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 20px;
  border: 1px solid #ccc; /* Set your desired border color */
  border-radius: 4px; /* Set your desired border-radius */
  background-color: #fff; /* Set your desired background color */
  color: #333; /* Set your desired text color */
}

/* Style for the custom reason textarea */
.modalContent textarea {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 20px;
  border: 1px solid #ccc; /* Set your desired border color */
  border-radius: 4px; /* Set your desired border-radius */
  background-color: #fff; /* Set your desired background color */
  color: #333; /* Set your desired text color */
}

/* Style for the return quantity input */
.modalContent input[type="number"] {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 20px;
  border: 1px solid #ccc; /* Set your desired border color */
  border-radius: 4px; /* Set your desired border-radius */
  background-color: #fff; /* Set your desired background color */
  color: #333; /* Set your desired text color */
}

/* Style for the submit and cancel buttons */
.modalContent button {
  background-color: #007bff; /* Set your desired button background color */
  color: #fff; /* Set your desired button text color */
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 4px; /* Set your desired border-radius */
}

.modalContent button:last-child {
  background-color: #ccc; /* Set your desired cancel button background color */
  color: #333; /* Set your desired cancel button text color */
}
/* Add this CSS to your stylesheet or component */
.tracking-url-container {
  margin-top: 10px;
}

.tracking-url-label {
  font-weight: bold;
}


