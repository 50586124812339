.product {
    .card {
      width: 100%;
      max-width: 500px;
      padding: 1rem;
    }
    form {
      label {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
      }
      input[type="text"],
      input[type="number"],
      input[type="file"],
      input[type="email"],
      select,
      textarea,
      input[type="password"] {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 1rem auto;
        width: 100%;
        border: 1px solid #777;
        border-radius: 3px;
        outline: none;
      }
  
      // textarea {
      //   width: 100%;
      // }
  
      .progress {
        background-color: #aaa;
        border: 1px solid transparent;
        border-radius: 10px;
        .progress-bar {
          background-color: var(--light-blue);
          border: 1px solid transparent;
          border-radius: 10px;
          color: #fff;
          font-size: 1.2rem;
          font-weight: 500;
          padding: 0 1rem;
        }
      }
    }
  }
  
  .group {
    border: 1px solid var(--dark-blue);
    padding: 5px;
  }

  .imageInputContainer {
    margin-bottom: 10px; /* Add margin for better spacing */
  }

  .imagePreviewContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
  
  .imagePreview {
    width: 300px;
   
    border-radius: 5px;
    overflow: hidden;
    padding-left: 5px;
    padding-right: 5px;
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .removeImageButton {
    background-color: #dc3545;
    color: #fff;
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #c82333;
    }
  }

  .removeSizeButton {
    margin-top: 5px;
    background-color: #ff6262;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  
    &:hover {
      background-color: #e03b3b;
    }
  }
  