// LatestProducts.module.scss

.gridContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.latprod {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  padding-top: 23px;
  border-top: 1px solid #ccc;
}

.viewAllLink {
  padding-right: 20px; // Adjust padding for smaller screens
  a {
    text-decoration: underline;
  }
}

.topSection {
  display: flex;
  flex-direction: column; // For smaller screens, stack the elements vertically
  align-items: center; // Center the items vertically
  margin-bottom: 20px; // Adjust margin for smaller screens
}

.toptext {
  h4 {
    padding-left: 0; // Remove left padding for smaller screens
    text-align: center; // Center the text
    margin-bottom: 10px; // Adjust margin for smaller screens
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; // Adjust gap for smaller screens
}

// Adjust the width of each product item for smaller screens
.productItem {
  width: 100%; // Occupy full width on smaller screens
}

// Optional: Adjust styles for tablets
@media (min-width: 768px) {
  .gridContainer {
    padding-left: 35px; // Revert padding for tablets
  }

  .latprod {
    margin: 5rem; // Adjust margin for tablets
  }

  .productItem {
    width: 48%; // Adjust width for tablets
  }
}

// Optional: Adjust styles for larger screens (13-inch screens)
@media (min-width: 1440px) {
  .latprod {
    margin: 0rem; // Adjust margin for larger screens
  }

  .productItem {
    width: 30%; // Adjust width for larger screens
  }
}
