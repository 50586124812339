.design-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 30px;
}
.gridc{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.viewAllContainer {
  text-align: center;
  margin-top: 20px;
}

.viewAllText {
  
  color: black;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s ease;
}

.viewAllText:hover {
  
  color: orangered;
  text-decoration: underline;
}


@media screen and (max-width: 600px) {
  .design-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.design-grid div {
  cursor: pointer;
}

.design-grid img {
  width: 100%;
  // height: 268px;
  object-fit: contain;
  display: block;
  border-radius: 8px;
}

.design-grid img:hover {
  transform: scale(1.1);
}

.heading {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
}

.options-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
}

.options-container .option {
  width: 300px;
  margin: 0 10px;
  cursor: pointer;
}

.linkstyle {
  color: #007185;
  font-size: 14px;
  cursor: pointer;
}

.linkstyle:hover {
  text-decoration: underline;
  color: orangered;
}
.linkstyle1 {
  color: #007185;
  font-size: 14px;
  cursor: pointer;
}

.linkstyle1:hover {
  text-decoration: underline;
  color: orangered;
}

.option-label {
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
}

.options-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
}

.options-container .option {
  // width: calc(50% - 20px);
  margin: 10px;
  cursor: pointer;
}

@media screen and (min-width: 600px) {
  .options-container .option {
    width: 200px;
  }.options-container .option2 {
    width: 400px;
  }
}

.options-container .option img {
  width: 100%;
  height: 260px;
  object-fit: contain;
  border-radius: 8px;
}

.options-container .option img:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 800px) {
  .padiv {
    padding-left: 0px !important;
    padding-right: 10px !important;
    width: 200px !important;
  }
  .linkstyle {
    padding-left: 25px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.customize-section {
  width: 60%; /* Each section takes up 50% of the container */
}
.curated-section {
  width: 40%; /* Each section takes up 50% of the container */
}
.sectionHeading {
  text-align: center;
  padding-top: 20px;
}

.optionsWrapper {
  display: flex;
  justify-content: space-around; /* Adjusts spacing between the sections */
  position: relative; /* Add this for the divider positioning */
}

.divider {
  position: absolute;
  height: 95%;
  width: 2px;
  background-color: #ccc;
  left: 60%; /* Adjust this to position the divider between sections */
}

@media screen and (max-width: 600px) {
  .optionsWrapper {
    flex-direction: column; /* Stack sections vertically */
    align-items: center; /* Center align items */
  }
  .section {
    width: 90%; /* Each section takes up 90% of the container */
  }

  .divider {
    display: none; /* Hide divider on small screens */
  }
}
