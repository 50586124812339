.product {
  margin-left: 50px;
  .dropdown {
    position: relative;
    width: 230px;
    filter: url(#goo);
  }
  .linkstyle {
    color: #007185;
    font-size: 14px;
    cursor: pointer;
  }

  .linkstyle:hover {
    text-decoration: underline;
    color: orangered;
  }
  .dropdown__face,
  .dropdown__items {
    background-color: #fff;
    padding: 20px;
    border-radius: 25px;
  }

  .dropdown__face {
    display: block;
    position: relative;
  }

  .dropdown__items {
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
    list-style: none;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.93, 0.88, 0.1, 0.8);

    &::before {
      content: "";
      background-color: #fff;
      position: absolute;
      bottom: 100%;
      right: 20%;
      height: 40px;
      width: 20px;
    }
  }

  .dropdown__arrow {
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    position: absolute;
    top: 50%;
    right: 30px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: right;
  }

  input {
    display: none;
    &:checked ~ .dropdown__items {
      top: calc(100% + 25px);
      visibility: visible;
      opacity: 1;
    }
  }

  /* ... (your existing styles) */

  .card {
    padding: 1rem;
    margin-top: 1rem;
    box-shadow: none;
    border: 1px solid #ccc;
    margin-right: 30px;
  }

  .reviews {
    margin: 0px 310px 0px 240px;
  }

  .userName {
    font-size: 13px;
    line-height: 19px;
  }
  .details {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    margin-right: 50px;
    padding-bottom: 60px;
    border-bottom: 1px solid #ccc;

    .img {
      width: 35%;
      padding-top: 20px;
      border: 1px solid #ccc;
      margin-right: 30px;
      border-radius: 3px;
      position: relative;
      .inside {
        position: absolute;
        z-index: 5;
        cursor: pointer;

        width: 10%; // Adjusted width to 25% to show only a quarter
        height: 8%; // Adjusted height to 25% to show only a quarter
        top: 0;
        right: 0;
        border-radius: 0 0 0 50%;
        transition: all 0.5s;
        overflow: hidden;

        .icon {
          position: absolute;
          top: 28%;
          right: 29%;
          transform: translate(
            25%,
            -25%
          ); // Adjusted transform to position it correctly
          color: white;
          opacity: 1;
        }
      }
      img {
        width: 100%;
      }
    }

    .content {
      width: 55%;
      padding-right: 20px;
      & > * {
      }
      .price {
        font-weight: 400;
      }

      .count {
        display: flex;
        margin-bottom: 30px;
        align-items: center;
        & > * {
          margin-right: 1rem;
        }
      }
    }
  }
}
.soldOutText {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333; // Adjust the color as needed
}
.notifyButton {
  padding: 10px 20px;
  background-color: #bc3b59; // Adjust the color as needed
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #9b2847;
  }
}
.row {
  width: 100%;
  display: flex;
  margin-top: 10px;
}
.col1 {
  width: 25%;
  margin-left: 20px;
}
.col2 {
  margin-left: 10px;
  width: 75%;
}
.ulist {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
}

/* Style the list items */
.ulist li {
  // margin-right: 0px;
  font-size: 14px;
}

/* Style the link */
.ulist a {
  text-decoration: none;
  color: #000; /* Adjust the color as needed */
  // font-weight: bold;
  display: inline-block; /* Add display property to inline-block */
  padding: 8px;
}

/* Add a class for the back arrow icon */
.backArrow {
  margin-left: -4px;
  margin-right: -7px;
  /* Adjust margin as needed */
}
.cart {
  margin-top: 5px;
  margin-left: 30px;
}
.incredecre {
  font-size: 1.6rem;
  font-weight: 400;
  padding: 1px 8px;
  // margin: 0px 50px 0px px -1px;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.rev {
  font-size: 14px;
  line-height: 20px;
  color: #0f1111;
}
.revdiv {
  margin-top: 10px;
  margin-bottom: 20px;
}
.react-star-rate {
  font-size: 5px !important;
}

.react-star-rate.style {
  /* Your styles here */
  font-size: 25px !important;
}
.wishlistIcon {
  font-size: 24px; // Adjust the font size as needed
  color: var(--color-danger); // Set the color to red or your desired color
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.2); // Add a scaling effect on hover if desired
  }
}
.prodname {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
  padding-top: 1rem;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}
.tax {
  padding-left: 1.5rem;
}
.priceSym {
  top: -0.75em;
  font-size: 13px;
  position: relative;
}
.priceData {
  font-size: 28px;
}
.brand {
  margin-top: 50px;
}
.detailsTable {
  border-collapse: collapse;
  margin-top: 23px;
  margin-bottom: 20px;
}

.detailsTable td {
  padding: 6px;
  width: 120px;
}

.detailsLabel {
  font-weight: bold;
  text-align: left;
  font-size: 14px;
  padding-right: 50px; /* Adjust as needed for spacing */
}

.detailsValue {
  font-size: 14px;
  text-align: left;
}
.desc {
  border-top: 1px solid #ccc;
  font-size: 16px;
  padding-top: 20px;
  line-height: 24px;
}
.description {
  font-size: 14px;
  text-align: justify;
  color: #0f1111;
  font-weight: 400;
}
.addToCart {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  background: orangered;
  border: none;
  font-size: 1rem;
  padding: 10px 30px;
  border-radius: 30px;
  margin-top: 30px;
  margin-bottom: 15px;
  letter-spacing: 1.2px;
}
.addToCart:hover {
  cursor: pointer;
  color: orangered;
  background: white;
  border: orangered;
}

.addToCart2 {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  background: rgb(82, 115, 33);
  border: none;
  font-size: 1rem;
  padding: 10px 30px;
  border-radius: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  letter-spacing: 1.2px;
}
.addToCart2:hover {
  cursor: pointer;
  color: green;
  border: green;
  background: white;
  border: green;
}

.repliesSection {
  padding-top: 10px;
  border-top: 1px solid #ccc;
}
.center-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw; // Make the container full height of the viewport
}
.AdminName {
  font-size: 13px;
  line-height: 19px;
}
.reply {
  margin-bottom: 20px;
}
.adminReply {
}

@media screen and (max-width: 800px) {
  .product {
    margin-left: 20px;
    .padiv {
      padding-left: 0px !important;
    }
    .details {
      flex-direction: column;
      margin-right: 0px;
      .img {
        width: 100%;
      }

      .content {
        width: 100%;
      }
    }
    .reviews {
      margin: 0px;
    }
  }
  .row {
    display: block;
  }
  .col1 {
    width: 100%;
  }
  .col2 {
    margin-left: 21px;
    margin-top: 24px;
    width: 75%;
  }
  .card {
    margin-bottom: 50px;
  }
}
/* ... (your existing styles) */

.dropdownContainer {
  position: relative;
}

.selectedSize {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.dropdownIcon {
  margin-left: 5px; /* Add margin or adjust as needed */
}

.sizeOptions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 1;
}

.sizeOptions li {
  padding: 10px;
  cursor: pointer;
}

.sizeOptions li:hover {
  background-color: #f0f0f0;
}

.dropdownContainer:hover .sizeOptions {
  display: block;
}

.reviewImages {
  display: flex;
  flex-wrap: wrap;
}

.reviewImage {
  margin-right: 10px;
  margin-bottom: 10px;
}
.carousel {
  max-height: 2px;
}

.description {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-family: "poppins";
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

/* ... (your existing styles) */
