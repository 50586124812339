.formcontainer {
  padding: 5px;
  justify-content: center;

  .input-container {
    margin-bottom: 20px;
  
    label {
      display: block;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333;
    }
  
    input[type="text"],
    input[type="email"] {
      width: 100%;
      padding: 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      margin-top: 5px;
    }
  
    
    
  }
  
  .radioImageContainer {
    display: grid;
    grid-template-columns: repeat(2, 150px); /* Two images per row, each taking 150px width */
    grid-gap: 10px; /* Adjust gap between images */
  }

  .radioImageLabel {
    position: relative;
    height: 150px; /* Set height of image container */
    width: 150px;
  }

  .radioImageInput {
    position: absolute;
    opacity: 0;
  }

  .radioImage {
    height: 150px; /* Set height of image container */
    width: 150px;
    object-fit: cover;
    border-radius: 5px;
    border: none; /* Remove border */
    transition: border-color 0.5s;
  }

  .radioImageInput:checked + label > .radioImage {
    border: 4px solid rgb(0, 34, 255); /* Thick border when selected */
  }

  .radioImageLabel:hover .radioImage {
    transform: scale(1.1); /* Enlarge the image on hover */
  }

  .required {
    color: red;
  }

  .error input[type="text"],
  .error input[type="email"] {
    border: 2px solid red; /* Add red border for error */
  }

  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }

  max-width: 800px;
  margin: 0 auto;

  h2 {
    text-align: center;
    font-size: 36px;
    color: #333;
    margin-bottom: 30px;
  }

  .section-card {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .section-checkbox {
    float: left;
    margin-right: 10px;
    padding-left: 120px;
  }

  .section-name {
    font-size: 24px;
    color: #007bff;
    margin-bottom: 15px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 30px;
 
  }

  .form-group {
    margin-bottom: 20px;
    display: none; /* Initially hide the fields */

    &.active {
      display: block; /* Show the fields when active */
      animation: fadeIn 0.5s ease; /* Add animation */
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(-20px); /* Start animation from above */
      }
      to {
        opacity: 1;
        transform: translateY(0); /* End animation at original position */
      }
    }

    label {
      display: block;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333;
    }

    select,
    input[type="text"],
    input[type="number"],
    input[type="file"],
    input[type="email"] {
      width: 100%;
      padding: 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
      margin-top: 5px;
    }

    input[type="file"] {
      margin-bottom: 10px;
    }
  }

  /* Styles for name, phone number, and email address fields */
  input#name,
  input#phoneNumber,
  input#email {
    margin-bottom: 20px;
  }
}

.submit-button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
}

