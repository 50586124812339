.privacyPolicy {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;

  h2 {
    color: #333;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    color: #666;
    margin-bottom: 20px;
  }
}
