/* Slider.module.scss */
$font-family-primary: "Montserrat", sans-serif;
$font-family-secondary: "Roboto Slab", serif;

.body {
  background-color: #000;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to bottom,
    transparentize(#0e1d33, 0.2),
    transparentize(#0e1d33, 0.8)
  );
}



.heroSlider {
  width: 100%;
  height: 42vh;

  .carouselCell {
    width: 100%;
    height: 100%;
    background-repeat: none;
    background-size: cover; /* Updated property */
    background-position: center;
    
    background-color: #0e1d33;

  

    .inner {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      color: white;
      text-align: center;

      .subtitle {
        font-family: $font-family-secondary;
        font-size: 2.2rem;
        line-height: 1.2em;
        font-weight: 200;
        font-style: italic;
        letter-spacing: 3px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 5px;
      }

      .title {
        font-family: $font-family-primary;
        font-size: 3rem;
        line-height: 1.2em;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-bottom: 40px;
        color: white;
      }

      .btn {
        border: 1px solid #fff;
        padding: 14px 18px;
        text-transform: uppercase;
        font-family: $font-family-primary;
        font-size: 0.8rem;
        letter-spacing: 3px;
        color: #fff;
        text-decoration: none;
        transition: all 0.2s ease;

        &:hover {
          background: #fff;
          color: #000;
        }
      }
    }
  }

  .flickity-prev-next-button {
    
    width: 80px;
    height: 80px;
    background: transparent;

    &:hover {
      background: transparent;
    }

    .arrow {
      fill: white;
    }
  }

  .flickity-page-dots {
    bottom: 30px;

    .dot {
      width: 30px;
      height: 4px;
      opacity: 1;
      background: rgba(255, 255, 255, 0.5);
      border: 0 solid white;
      border-radius: 0;

      &.is-selected {
        background: #ff0000;
        border: 0 solid #ff0000;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .heroSlider {

   
    width: 100%;
  }
  .carouselCell{
    background-size: contain;

  }

  .flickity-prev-next-button{
    display: none;
  }


}
