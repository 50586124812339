.product-list {
  width: 95%;
  .top {
    width: 100%;
    height: 50px;
    border-bottom: 2px solid #ccc;
    border-top: 2px solid #ccc;
    display: flex;
    justify-content: space-between;
    padding-right: 2rem;
    align-items: center;
    // flex-wrap: wrap;
    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      & > * {
        margin-right: 7px;
        cursor: pointer;
      }
    }

    .sort {
      label {
        font-size: 1.4rem;
        font-weight: 500;
        margin: 0 5px;
      }
      select {
        font-size: 1.4rem;
        font-weight: 500;
        border: none;
        padding-left: 0.5rem;
        // border-bottom: 1px solid #777;
        outline: none;
        width: 7rem;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .product-list {
    margin-left: 0px !important;
    .top {
      align-items: center;
      padding-bottom: 5px;
    }
  }
  .grid {
    gap: 10px !important; // Adjust the gap as needed
    justify-content: space-around !important; // Center the items on mobile

    > div {
      width: 40%; // Display two items in the same row
    }
  }
}
.prodfound {
  padding-right: 0.5rem;
  padding-left: 1rem;
}
.gridContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 5rem;
}

.grid {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px; // Adjust the gap as needed
  width: 100%;
  margin-top: 20px; // Add margin to separate from the top section
}
@media screen and (min-width: 800px) {
  .product-list{
    padding-left: 10px;
  }
}