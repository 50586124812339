// TopBand.module.scss

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.topBand {
  overflow: hidden;
  height: 19px;
  background-color: black;
  &:hover .scrollingText {
    animation-play-state: paused;
  }
  p{
    font-size: 1.4rem;
    color: yellow;
  }
}

.scrollingText {
  white-space: nowrap;
  width: 100%;
  animation: scroll 20s linear infinite;
  color: white; // Adjust the duration and timing function as needed
  // Adjust the delay as needed
}
@media screen and (max-width: 800px) {
  .scrollingText {
  white-space: nowrap;
  width: 100%;
  animation: scroll 15s linear infinite;
  color: white; // Adjust the duration and timing function as needed
  // Adjust the delay as needed
}
}