.card {
  width: 100%;
  max-width: 500px;
  padding: 1rem;
  margin-bottom: 15px;
}
h3 {
  font-weight: 300;
}
.btn {
  width: 50%;
  button {
    margin-top: 20px;
    padding: 8px 12px;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #4caf50;
      color: white;
    }
  }
}
.text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  h3 {
    margin-bottom: 0rem !important;
    font-size: 1.5rem;
  }
}
.priceSym {
  top: -0.5em;
  font-size: 13px;
  position: relative;
}
.priceSym1 {
  top: -0.3em;
  font-size: 13px;
  position: relative;
}
.priceData {
  font-size: 22px;
}
.priceData1 {
  font-size: 19px;
}