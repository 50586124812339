/* Profile.module.scss */

.container {
  display: flex;
  max-width: 1000px;
  margin-left: 200px;
}

.sidebar {
  width: 200px;
  // background-color: #f0f0f0;
  margin-right: 100px;
  border-right: 1px solid black;
  padding: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.sidebar ul li:last-child a {
  border-bottom: none;
}

.profile {
  padding: 20px;
}

.twoRows {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  margin-bottom: 20px;
}

// Profile.module.scss
.row1 {
  display: flex;
  margin-left: 50px; // Use flexbox layout
}

.label {
  flex-shrink: 0;
  font-size: 14px;
  margin-top: 18px; // Prevent label from shrinking
}
.label2 {
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  margin-top: 18px; // Prevent label from shrinking
}
// Other styles...

.addressTextarea {
  width: 300px;
  padding: 8px;
  font-family: inherit;
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
  font-size: 14px;
  outline: none;
  resize: none; // Prevent textarea resizing
}
.addressTextarea:hover {
  border-color: none; // Hide border on hover
}
// Other styles...

.userAvatar {
  width: 110px;
  height: 100px;
  margin-top: 10px;
  border-radius: 50%;
  margin-right: 20px;
}

.details {
  margin-top: 10px;
  display: grid;
  display: flex;
  justify-content: space-between;
}
.labels {
  flex: 1;
}

.values {
  flex: 1;
  margin-left: 20px; /* Adjust margin as needed for spacing */
}

.detailItem {
  margin-bottom: 10px;
  line-height: 1.6;
  font-size: 14px;
}

.detailLabel {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}

.detailValue {
  font-size: 14px;
  margin-bottom: 8px;
}

.logoutButton {
  background-color: #ff6347;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.logoutButton:hover {
  background-color: #ff4c2c;
}

.editButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 25px;
  margin-left: 115px;
}

.editButton:hover {
  background-color: #0056b3;
}

.inputWrapper {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
  }

  button[type="submit"] {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
}

.modalContent button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  border-radius: 4px;
}

.modalContent button:last-child {
  background-color: #ccc;
  color: #333;
}
/* Profile.module.scss */

/* Adjustments for smaller screens */
/* Profile.module.scss */

/* Adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    margin-left: 0;
  }

  .sidebar {
    width: 100%;
    margin-right: 0;
    border-right: none;
  }

  .profile {
    width: 100%;
    padding: 20px;
  }

  .userAvatar {
    width: 80px;
    height: 80px;
  }

  .addressTextarea {
    width: 100%;
  }

  .input {
    width: 100%;
  }

  .editButtonWrapper {
    display: flex;
    justify-content: center; // Center the buttons horizontally
    margin-top: 20px; // Add some top margin for spacing
  }

  .editButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
    margin-left: 20vw;
  }

  .editButton:hover {
    background-color: #0056b3;
  }

  .logoutButton {
    background-color: #ff6347;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
  }

  .logoutButton:hover {
    background-color: #ff4c2c;
  }
}

/* Other styles... */
