/* CreateCouponCode.module.scss */

.product {
  .card {
    width: 100%;
    max-width: 500px;
    padding: 2rem;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    h2 {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
      color: #333;
    }

    form {
      label {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        color: #555;
      }

      input[type="text"],
      input[type="number"],
      input[type="date"] {
        width: calc(100% - 2rem);
        padding: 1rem;
        margin: 0.5rem 0;
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;
        position: relative;

        &::-webkit-calendar-picker-indicator {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
          width: 2rem; /* Adjust the width as needed */
          height: 2rem; /* Adjust the height as needed */
          padding: 0;
          margin: 0;
          cursor: pointer;
        }
      }

      /* Style the input when it has a value */
      input[type="text"]:not(:placeholder-shown),
      input[type="number"]:not(:placeholder-shown),
      input[type="date"]:not(:placeholder-shown) {
        color: #333;
      }

      .btn {
        display: inline-block;
        padding: 1rem 2rem;
        font-size: 1.6rem;
        font-weight: 500;
        cursor: pointer;
        background-color: #3498db;
        color: #fff;
        border: none;
        border-radius: 4px;
        transition: background-color 0.3s ease;

        &.btnPrimary {
          background-color: #27ae60;
        }

        &:hover {
          background-color: #2980b9;
        }
      }
    }
  }
}

.group {
  border: 1px solid #3498db;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}

/* Media Query for smaller screens */
@media (max-width: 600px) {
  .product .card {
    max-width: 100%;
  }
}
