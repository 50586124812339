/* manageVideoReviews.module.scss */

.videoReviewsContainer {
  max-width: 600px;
  margin: 0 auto;
}

.videoReviewItem {
  margin-bottom: 20px;
}

.videoReviewLink {
  display: inline-block;
  color: #007bff;
  text-decoration: underline;
  margin-right: 10px;
}

.deleteButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.deleteButton:hover {
  background-color: #c82333;
}
