/* InstagramReels.module.scss */

.instagramReels {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding-bottom: 60px;
}
.slick-dots {
  display: none;
}
.topSection {
  display: flex;
  flex-direction: column; // For smaller screens, stack the elements vertically
  align-items: center; // Center the items vertically
  margin-bottom: 20px; // Adjust margin for smaller screens
}

.viewAllLink {
  padding-right: 20px;
  a {
    text-decoration: underline;
  }
}

.toptext {
  h4 {
    padding-left: 0; // Remove left padding for smaller screens
    text-align: center; // Center the text
    margin-bottom: 10px; // Adjust margin for smaller screens
    margin-top: 20px;
  }
} // InstagramReels.module.scss

.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.prevButton {
  left: 10px;
}

.nextButton {
  right: 10px;
}

.reel {
  position: relative;
  width: calc(20% + 30px); /* 5 reels in a row with 10px gap */
  overflow: hidden;
  cursor: pointer;

  img {
    width: 80%;
    height: auto;
    display: block;
  }

  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;

    svg {
      display: block;
    }
  }

  &:hover .overlay {
    opacity: 1;
  }
}
