.mobileslider {
  text-align: center;
}

.sliderbutton {
  margin: 0 10px; /* Adjust margin as needed */
  padding: 5px 10px; /* Adjust padding as needed */
  background-color: #ccc; /* Button background color */
  color: #333; /* Button text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.reel {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: 7px;
  img {
    width: 100%;
    height: auto;
    display: block;
  }

  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;

    svg {
      display: block;
    }
  }

  &:hover .overlay {
    opacity: 1;
  }
}
.embla {
  max-width: 146rem;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 0rem;
  --slide-size: 50%;
  padding: 0 !important;
  margin-bottom: 20px;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  border-radius: 1.8rem;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slide-height);
}
.embla__controls {
  margin-bottom: 1.8rem;
}
.embla__buttons {
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;

  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 8rem; /* Increase or decrease the width as needed */
  height: 4rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: var(--detail-high-contrast);
  width: 8rem; /* Increase or decrease the width as needed */
  height: 4rem;
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: "";
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(--text-body);
}
@media (max-width: 800px) {
  .embla__button {
    min-width: 3rem; /* Increase or decrease the width as needed */
    min-height: 3rem;
  }
}
/* Add this CSS at the end of your stylesheet */

@media (max-width: 800px) {
 /* Add this CSS at the end of your stylesheet */

@media (max-width: 800px) {
  .reel {
    display: flex;
    justify-content: center; /* Center align the content within the reel */
    align-items: center; /* Center align vertically if needed */
  }
}

}
