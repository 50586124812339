// CheckoutDetails.module.scss
.checkboxLabel {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.btn {
  margin-top: 20px;
  width: 60%;
  display: flex;
  justify-content: center; // Center the content horizontally
  align-items: center;
  margin-left: 83px;
  button {
    padding: 8px 12px;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #4caf50;
      color: white;
    }
  }
}
.copyCheckbox {
  margin-left: 0.5rem;
}
.checkout {
  width: 90%;
  position: relative;
input.invalidInput {
  border: 1px solid red !important;
}

  h2 {
    margin-bottom: 3rem;
    margin-left: 110px;
  }

  .form {
    width: 100%;
    display: flex;

    .formSection {
      width: 100%;

      &:not(:last-child) {
        margin-left: 100px;
      }

      .card {
        padding: 25px 30px;
        width: 100%;
        max-width: 500px;

        h3 {
          font-weight: 400;
          margin-bottom: 2rem;
        }
      }

      .copyButton {
        width: 100%;
        margin-top: 1rem;
      }

      label {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      input[type="text"],
      .select,
      .card-details {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 0.5rem 0;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;
        outline: none;
        box-sizing: border-box;
      }
    }
    .formSection1 {
      width: 100%;

      &:not(:last-child) {
        margin-left: 0px;
      }

      .card {
        padding: 25px 30px;
        width: 100%;
        max-width: 500px;

        h3 {
          font-weight: 400;
          margin-bottom: 2rem;
        }
      }

      .copyButton {
        width: 100%;
        margin-top: 1rem;
      }

      label {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      input[type="text"],
      .select,
      .card-details {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 0.5rem 0;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;
        outline: none;
        box-sizing: border-box;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  section{
    padding: 0;
    padding-top: 40px;
  }
  .checkout {
    h2{

      margin-left: 0!important;
    }
    .form {
      flex-direction: column;

      .formSection {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        margin-left: 0px !important;
      }
    }
    .btncheck{
       margin: 30px auto !important; 
       display: block !important
    }
  }
}
.required {
  color: red; /* Set the color to red or any other color you prefer */
  margin-left: 5px; /* Add some spacing between the field label and the asterisk */
}
.errorOutline {
  border: 1px solid red;
}
