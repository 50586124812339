.search {
  margin: 5px 0;
  position: relative;
  
  flex: 1;

  .icon {
    position: absolute;
    top: 50%;
    left: 1rem;
    color: #ddd;
    
    margin-right: 1rem;
    transform: translateY(-50%);
  }

  input[type="text"] {
    display: block;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 1rem;
    background-color: #060e1b;
    color: #ddd;
    padding-left: 4rem;
    margin: 1rem auto;
    font-style: white;
    width: 100%;
    border: 0.5px solid grey;
    border-radius: 3px;
    outline: none;
  }
}
