/* ImageWithTextOverlay.module.scss */

.imageWithTextOverlay {
  position: relative;

  &--height-medium {
    height: 25rem;
  }

  &--wide {
    left: 50%;
    margin-left: calc(-50vw + (var(--scrollbar-width) / 2));
    margin-right: calc(-50vw + (var(--scrollbar-width) / 2));
    max-width: calc(100vw - var(--scrollbar-width));
    position: relative;
    right: 50%;
    width: calc(100vw - var(--scrollbar-width));
  }
}

.imageWithTextOverlayContainer {
  position: relative;
  // max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.imageWithTextOverlayImageWrapper {
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  [data-rimg="loaded"] {
    background-color: transparent;
    opacity: 1;
  }

  [data-rimg="lazy"],
  [data-rimg="loading"],
  [data-rimg="loaded"] {
    background-color: rgba(0, 0, 0, 0.1);
    transition: opacity 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    max-height: 400px;
  }
}

.imageWithTextOverlayContent {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1.5rem;

  @media screen and (min-width: 768px) {
    padding: 2.5rem;
  }

  .imageWithTextOverlayContentWrapper {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &--text-position-bottom-left,
  &--text-position-bottom-center,
  &--text-position-bottom-right {
    .imageWithTextOverlayContentWrapper {
      justify-content: flex-end;
    }
  }
}

.imageWithTextOverlayHeading {
  font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-heading-2);

  @media screen and (min-width: 768px) {
    font-size: 8rem !important;
  }

  line-height: 1.3;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin: 0 0 0.5rem;
  color: inherit;
}

.imageWithTextOverlayText {
  font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  p {
    font-size: 20px;
    color: white;
    font-weight: 500;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.25rem;
  }

  line-height: 1.5;
  text-decoration: none;
  text-transform: none;
  max-width: 600px;
  margin: 0;
}

.imageWithTextOverlayButton {
  font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
  font-style: normal;
  font-weight: 600;
  display: inline-block;
  line-height: 1.4;
  color: black;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0px;
  cursor: pointer;
  background-color: #ccc;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s ease-out, background-color 0.2s ease-out,
    color 0.2s ease-out, box-shadow 0.2s ease-out;
  padding: 1.75rem 2rem;
  font-size: 1.625rem;
  margin-top: 1rem;

  &:hover {
    background-color: white;
    color: black;
    font-family: Helvetica, "Helvetica Neue", Arial, "Lucida Grande", sans-serif;
    font-style: normal;
    font-weight: 400;
    display: inline-block;
    line-height: 1.4;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
    cursor: pointer;
    border: 1px solid #000000;
    border-radius: 0;
    outline: none;
    transition: border-color 0.2s ease-out, background-color 0.2s ease-out,
      color 0.2s ease-out, box-shadow 0.2s ease-out;
    padding: 1.75rem 2rem;
    font-size: 1.625rem;
    margin-top: 1rem;
  }
}

.imageWithTextOverlayButtonStylePrimary {
  // Add any specific styles for the primary button variant
}

a {
  color: #000000;

  &:hover {
    color: inherit;
  }
}
