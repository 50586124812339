.pagination {
  list-style: none;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 2px solid #ccc;
  bacolor: black;
  display: flex;
  justify-content: center;
  align-items: center;

  .hidden {
    display: none;
  }

  li {
    font-size: 1.4rem;
    margin-top: 3rem;
    // border: 1px solid #ccc;
    min-width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
.pages {
  margin-top: 3rem;
  margin-bottom: 3rem;
  .page {
    color: var(--dark-blue);
  }
  span{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  display: flex;
  justify-content: center;
  align-items: center;
}
.active {
  background-color: var(--dark-blue);
  color: #fff;
}
