.thankYouContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  padding: 0 20px;

  @media (min-width: 768px) {
    padding: 0;
  }
}

.thankYouMessage {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: white; /* Text color */

  @media (max-width: 768px) {
    font-size: 20px;
  }

  .animateLeft {
    animation: slideFromLeft 1s ease forwards;
  }

  .animateRight {
    animation: slideFromRight 1s ease forwards;
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.okayButton {
  padding: 12px 24px;
  font-size: 18px;
  border: none;
  border-radius: 30px;
  background-color: #007bff; /* Default blue color */
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease; /* Add transition for transform and background-color */
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)); /* Initial gradient with transparent color */
  animation: fadeIn 2s ease forwards; /* Apply fade-in animation */

  &:hover {
    transform: scale(1.05); /* Scale on hover */
    background-color: #0070c1;
    color: #ffffff; /* Change text color on hover */
  }

  &:active {
    transform: scale(0.95);
  }

  @media (max-width: 768px) {
    font-size: 16px;
    width: 100%;
    max-width: 200px;
  }
}



