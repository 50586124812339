/* YourFooter.module.scss */

$footer-color: rgb(10, 25, 48); /* Define the footer color variable */

.footer {
  display: flex;
  flex-flow: row wrap;
  padding: 30px 60px 20px 60px; /* Adjust padding */
  color: black; /* Set text color to white */
  background-color: #0a1930; /* Use the defined footer color */
  border-top: 1px solid #e5e5e5;
  height: 290px;
  margin-top: auto;
}
/* YourFooter.module.scss */

/* ... (your existing styles) */

.nav__item {
  &:nth-child(3) {
    flex: 1 15%; /* Adjust the flex property and width as needed */
    margin-right: 1em; /* Add margin between items */
  }

  &:nth-child(4) {
    flex: 1 60%; /* Adjust the flex property and width as needed */
    margin-right: 2em; /* Add margin between items */
  }

  &:nth-child(5) {
    flex: 1 17%;
    margin-left: 20px; /* Adjust the flex property and width as needed */
  }
}

/* ... (the rest of your existing styles) */

.footer > * {
  flex: 1 100%;
}

.footer__addr {
  margin-right: 1.25em;
  margin-bottom: 2em;
}
.footer__addr h2:hover,
.footer__btn:hover,
.footer a:hover {
  color: orangered; /* Change color on hover as needed */
}
.footer__logo {
  max-width: 300px; /* Set the maximum width for the logo */
  width: 100%; /* Ensure the logo takes the full available width */
  height: auto;
  margin-bottom: 10px;
}

.footer__addr h2 {
  margin-top: 1.3em;
  font-size: 18px; /* Increase font size */
  font-weight: 400;
  color: #fff;
}

.nav__title {
  font-weight: 400;
  font-size: 18px; /* Increase font size */
  color: white;
}

.footer address {
  font-style: normal;
  color: black;
}

.footer__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: max-content;
  background-color: #ccc;
  border-radius: 100px;
  color: black; /* Set text color to white */
  line-height: 0;
  margin: 0.6em 0;
  font-size: 1.2rem; /* Increase font size */
  padding: 0 1.3em;
}

.footer ul {
  list-style: none;
  padding-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.footer__nav {
  display: flex;
  flex-flow: row wrap;
}

.footer__nav > * {
  flex: 1 50%;
  margin-right: 1em;
}
.addr {
  color: #ccc;
  font-size: 16px;
}
.addr2 {
  color: #ccc;
  font-size: 14px;
}

.nav__ul a {
  color: #ccc;
}

.nav__ul--extra {
  column-count: 2;
  column-gap: 1.25em;
}

.legal {
  margin-top: 50px;
  border-top: 1px solid grey;
  padding-top: 15px;
  display: flex;
  justify-content: space-between; /* Adjust to push the items to the ends */
  color: white;
  p {
    color: white;
  }
}

.legal__links {
  /* Remove flex property to ensure it doesn't take unnecessary space */
}

.heart {
  color: white; /* Set heart color to white */
}

.socialIcons {
  display: flex;
  font-size: 2rem;

  svg {
    margin-right: 1rem;
    color: white; /* Set icon color to white */
    transition: color 0.3s ease;
    margin-bottom: 1rem;

    &:hover {
      color: orangered; /* Adjust hover color as needed */
    }
  }

  @media (max-width: 768px) {
    flex-direction: row; /* Stack icons vertically on smaller screens */
    align-items: center; /* Center align icons */
    text-align: center; /* Center align text */

    svg {
      margin-right: 0;
      margin-bottom: 3rem; /* Add space between stacked icons */
    }
  }
}

@media screen and (min-width: 24.375em) {
  .legal .legal__links {
  }
}

@media screen and (min-width: 40.375em) {
  .footer__nav > * {
    flex: 1;
  }

  .footer__addr {
    flex: 1 0px;
  }

  .footer__nav {
    flex: 2 0px;
  }
}
@media screen and (max-width: 800px) {
  .footer__nav {
    display: grid;
    grid-gap: 1em;
    margin-top: 40px;
  }
  .addr {
    margin-left: 10px !important;
  }

  .footer__nav > * {
    flex: 1 50% !important;
    margin-right: 1em !important;
  }
  .nav__item {
    margin-left: 10px !important;
  }
  .footer ul {
    list-style: none;
    padding-left: 0;
    margin-left: 0px !important;
  }
  .nav__title {
    font-weight: 400;
    font-size: 18px; /* Increase font size */
    color: white;
    margin-left: 0px !important;
  }
  .nav__item {
    &:nth-child(3) {
      flex: 1 15%; /* Adjust the flex property and width as needed */
      margin-right: 1em;
      margin-bottom: 0px !important; /* Adjust the flex property and width as needed */
      /* Add margin between items */
    }

    &:nth-child(4) {
      flex: 1 60%; /* Adjust the flex property and width as needed */
      margin-right: 2em; /* Adjust the flex property and width as needed */
      /* Add margin between items */
    }

    &:nth-child(5) {
      flex: 1 17%; /* Adjust the flex property and width as needed */
    }
  }
  .nav_icons {
    display: none;
  }
  .legal .legal__links {
    // margin-left: 130px;
  }
  .footer__nav > * {
    margin-bottom: 18px; /* Remove bottom margin for grid layout */
  }
  .footer {
    height: 100%;
    padding: 30px 23px 32px 35px;
  }
  .socialIcons {
    font-size: 1.8rem;
    a {
      margin-right: 12px;
    }
  }
}
