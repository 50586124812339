
// PantForm.module.scss

.pant-form {
    max-width: 1440px;
    margin: 20px auto;
    padding: 40px;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    // Desktop layout
    .desktop-layout {
        display: flex;
        justify-content: space-between;

        .column {
            flex: 1.3; // Adjust the flex value to increase the width of the columns
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }

            input[type="tel"].invalid,
            input[type="email"].invalid {
                border: 2px solid red;
            }

            .error-message {
                color: red;
                font-size: 14px;
                margin-top: 5px;
            }
        }
    }

    // Mobile layout
    @media (max-width: 767px) {
        .desktop-layout {
            flex-direction: column;
            
            .column {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }

    .image-previews {
        display: flex;
        max-width: 600px;
        margin-top: 10px;
        overflow-x: auto; /* Add this line */
      }
      
      .preview-image {
        width: 600px; /* Adjust as needed */
        height: auto; /* To maintain aspect ratio */
    
      }

      
         // Additional
            
      
      
    


    h1 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 24px;
        color: #333;
    }

    form {
        display: flex;
        flex-direction: column;

        label {
            margin-bottom: 15px;
            font-size: 18px;
            color: #555;
        }

        input[type="text"],
        input[type="tel"],
        input[type="email"],
        input[type="file"] {
            width: 100%;
            padding: 12px;
            margin-bottom: 20px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 16px;
            outline: none;

            &:focus {
                border-color: #007bff;
            }
        }

        input[type="tel"] {
            width: calc(100% - 24px); /* Adjusting for input padding */
        }

        input[type="file"] {
            cursor: pointer;
        }
        select {
            width: 100%;
            padding: 12px;
            margin-bottom: 20px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 16px;
            outline: none;
            background-color: #fff; // Background color
            transition: border-color 0.3s ease, background-color 0.3s ease;

            &:focus {
                border-color: #007bff;
                background-color: #fff; // Background color on focus
            }
        }

        select {
            appearance: none; // Remove default styling
            cursor: pointer; // Show pointer on hover
        }

        select:focus {
            outline: none; // Remove default focus styling
        }

        button[type="submit"] {
            align-self: center;
            padding: 12px 24px;
            margin-top: 20px;
            border: none;
            border-radius: 5px;
            background-color: #007bff;
            color: #fff;
            font-size: 18px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #0056b3;
            }
        }
    }
}
.linkstyle {
  color: #007185;
  font-size: 14px;
  cursor: pointer;
}

.linkstyle:hover {
  text-decoration: underline;
  color: orangered;
}
@media screen and (max-width: 800px) {
  .padiv {
    padding-left: 0px !important;
  }
}