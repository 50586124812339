// CheckoutForm.module.scss

.checkout {
  position: relative;
  width: 75%;
  header {
    z-index: 0 !important;
  } /* QRCode.module.scss */
  .zelleVenmoButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    .button {
      width: 124px;
      margin: 0 18px;
      position: relative;
      overflow: hidden;
      background: none;
      font-family: Arial, sans-serif;
      color: #ffffff;
      box-shadow: none;
      border-radius: 4px;
      border: 0;
      padding: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: all 0.2s ease;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        margin-right: 4px;
      }

      &:hover {
        filter: contrast(115%);
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }

      .arrow {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }

  .payWithCardToggle {
    h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .arrow {
        margin-left: 8px;
        cursor: pointer; // Adjust the spacing between text and arrow
      }
    }
  }

  .pay {
    margin-top: 2rem;

    .payWithCardToggle {
      &:hover {
        .card {
          border-color: #5469d4;
          box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
        }
      }
    }

    p {
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }
  }

  .card {
    width: 100%;
    max-width: 530px;
    padding: 25px 30px;

    h3 {
      font-weight: 300;
    }
  }

  h2 {
    margin-bottom: 3rem;
  }

  form {
    width: 100%;
    display: flex;

    div {
      width: 100%;
    }

    label {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
    }

    input[type="text"],
    .select,
    .card-details {
      display: block;
      font-size: 1.6rem;
      font-weight: 300;
      padding: 1rem;
      margin: 1rem auto;
      width: 100%;
      border: 1px solid #777;
      border-radius: 3px;
      outline: none;
    }
  }
}

@media screen and (max-width: 700px) {
  .checkout {
    form {
      flex-direction: column;

      div {
        width: 100%;
      }
    }
  }
}

.button.secondary {
  background: #dcdcdc;
  color: #333;
}

.button.secondary:hover {
  background: #c4c4c4;
}

.button.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    margin-right: 8px;
  }
}

// Payment Element styles
#payment-element {
  margin-bottom: 2rem;
}

#payment-message {
  color: rgb(105, 115, 134);
  color: red;
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  position: relative;
}

.button:hover {
  filter: contrast(115%);
}

.button:disabled {
  opacity: 0.5;
  cursor: default;
}
.payWithCardToggle {
  h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .arrow {
      margin-left: 8px;
      cursor: pointer;
    }
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  color: white; /* Add text color */
  font-size: 50px; /* Add font size */
  z-index: 9999; /* Increase z-index further */
}

@import url("https://fonts.googleapis.com/css2?family=Corben&family=Mulish&family=Outfit&family=Raleway:wght@300&display=swap");

.qrCodeContainer {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  border-radius: 20px 20px 20px 20px;
  background: #d3dfed;
}

.qrCodeBox {
  height: 550px;
  width: 355px;
  background: #fffffd;
  border-radius: 20px 20px 20px 20px;
}

.qrCodeSquareFrame {
  width: 320px;
  height: 320px;
  border-radius: 10px;
  margin: 17px;
  border: solid 1px #4677c6;
  position: relative;
}

.centered {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qrcode {
  max-width: 100%; /* Set the maximum width to the container width */
  max-height: 100%;
  img {
    max-width: 100%; /* Set the maximum width to the container width */
    max-height: 100%;
  } /* Set the maximum height to the container height */
}

.bubble_01 {
  width: 210px;
  height: 180px;
  background: #3685fe;
  position: absolute;
  border-radius: 15px 0 15rem 0;
}

.bubble_02 {
  width: 155px;
  height: 110px;
  background: #3685fe;
  position: absolute;
  top: 210px;
  left: 165px;
  border-radius: 100% 0 10px 0;
}

.text {
  width: 90%;
  flex-direction: column;
  text-align: center;
  font-family: Outfit;
  font-size: 18px;
  color: #75767a;
  margin: 10px;
  margin-bottom: 20px; /* Add margin-bottom to create space between paragraphs */
  margin-top: 20px; /* Add margin-bottom to create space between paragraphs */
}

.one {
  font-weight: 700;
  font-size: 25px;
  color: #101b39;
  margin-top: 10px; /* Add margin-bottom to create space below the first paragraph */
  margin-bottom: 10px; /* Add margin-bottom to create space below the first paragraph */
}

.two {
  margin-top: 10px; /* Add margin-top to create space above the second paragraph */
}
@media screen and (max-width: 800px) {
  .checkout{
    width: 100%;
  }
  .card{
    margin-bottom: 50px;
  }
}