.whatsappButton {
  position: fixed;
  bottom: 8rem;
  right: 0;
  width: 58px;
  height: 61px;
  z-index: 999999;
  background: green;
  border-radius: 100% 0 0 100%;
  padding: 0.5rem;
  box-shadow: 0px 2px 5px 2px #00000036;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.3s ease;

//   &:hover {
//     background: #039c039c; /* Change the hover background color as needed */
//   }

  svg {
    margin-right: -1rem;
  }
}

.instagramButton {
  position: fixed;
  bottom: 155px; /* Adjusted to match WhatsApp button position */
  right: 0px;
  width: 58px;
  height: 61px;
  z-index: 999999;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  ); /* Instagram's brand color */
  border-radius: 100% 0 0 100%;
  padding: 0.5rem;
  box-shadow: 0px 2px 5px 2px #00000036;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background: radial-gradient(
      circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%
    ); /* Darker shade on hover, you can customize this */
  }

  svg {
    margin-right: -1rem;
  }
}
@media (max-width: 768px) {
  .whatsappButton {
    width: 48px;
    height: 20;
    padding: 0.3rem;
    margin-right: 0px;
  }
  .instagramButton {
    width: 48px;
    height: 60px;
    padding: 0.3rem;
    margin-right: 0px; /* Add spacing between buttons */
  }

  .whatsappButton svg {
    margin-right: -0.9rem;
  }
  .instagramButton svg {
    margin-right: -0.8rem;
  }
}
