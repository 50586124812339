.container {
  display: flex;
  height: 88vh;
}

.sidebar {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: white;
}

.canvas {
  flex: 2.5;
  overflow: hidden;
}
.colorPicker {
  display: flex;
  flex-wrap: wrap;
  margin-left: 5px;
}
/* CanvasModel.module.scss */

/* Styles for the buttons */
.button {
  background-color: lightgrey;
  border: none;
  font-weight: 400;
  color: black;
  padding: 9px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 11px;
  margin: 4px 2px;
  cursor: pointer;
}
.downloadButton {
  position: absolute;
  bottom: 20px; /* Adjust bottom position as needed */
  right: 70px; /* Adjust right position as needed */
  width: 50px; /* Adjust width and height as needed */
  height: 50px;
  cursor: pointer;
}
.submitButton {
  background-color: lightgrey;
  border: none;
  font-weight: 400;
  color: black;
  position: absolute;
  bottom: 20px; /* Adjust bottom position as needed */
  right: 20px; /* Adjust right position as needed */
  width: 50px; /* Adjust width and height as needed */
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
}
.intro {
  font-size: 14px;
  margin-top: 27px;
}
.intro1 {
  font-size: 11px;
  margin-top: 8px;
  margin-left: 5px;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
}
.heading {
  font-size: 15px;
  padding: 0;
  margin: 0;
  font-weight: 500;
  margin-left: 10px;
}
.headingWrapper {
  display: flex;
  padding: 10px;
  align-items: center;
  background-color: lightgray; // Adjust rectangle background color as needed
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
}
.subHeading {
  margin-left: 5px;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 10px;
}
.icon {
  margin-left: auto;
  margin-right: 10px; // Pushes the icon to the right end
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust as needed */
}

/* Additional styles for the buttons if needed */
.toggleButton {
  margin-left: 10px; /* Add some space between the buttons */
}

/* You can add more specific styles or override styles here */

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  pointer-events: none; /* Allows clicks to pass through the overlay */
}

.colorOption {
  width: 28px;
  height: 28px;
  margin: 2px;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 4px;
}
.selectedColor {
  transform: scale(1.3);
  border: 1px solid black;
}

.collarTypes {
  display: flex;
  flex-wrap: wrap;
}
.colorOption {
  position: relative;
}

.colorName {
  position: absolute;
  top: -15px; /* Adjust this value to change the distance above the color */
  left: 50%;
  transform: translateX(-50%);
  font-size: 8px;
  background: black;
  padding: 2px 8px;
  color: white;
  white-space: nowrap;
}
.collarTypes img {
  width: 55px;
  height: 55px;
  margin: 5px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.collarTypes img:hover {
  transform: scale(1.1);
}

.selected {
  border: 2px solid blue;
}
@media screen and (max-width: 800px) {
  .container {
    display: flex;
    flex-direction: column; /* Change the direction to column */
    height: 88vh;
  }

  .sidebar {
    flex: 2 2; /* Adjust flex property as needed */
    overflow-y: auto;
    padding: 20px;
    background-color: white;
  }

  .canvas {
    flex: 2.5; /* Adjust flex property as needed */
    overflow: hidden;
  }
}
