.prodname {
  font-size: 16px !important;
}
.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11; // Ensure it is on top of the card
  text-align: center;
}

.soldOutText {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333; // Adjust the color as needed
}

.notifyButton {
  padding: 10px 20px;
  background-color: #bc3b59; // Adjust the color as needed
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #9b2847;
  }
}
.wrapper {
  width: 275px;
  height: 480px;
  @media screen and (max-width: 1401px) {
    width: 1280px * 0.277 !important;
    height: 802px * 0.277 !important;
  }
  background: white;
  margin: auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0;
  transform: scale(0.95);
  transition: box-shadow 0.5s, transform 0.5s;
  &:hover {
    transform: scale(1);
    box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.2);
  }
  &.sold-out {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(
        255,
        255,
        255,
        0.8
      ); // Adjust the color and opacity as needed
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .sold-out-text {
        font-size: 18px;
        margin-bottom: 10px;
        color: #333; // Adjust the color as needed
      }

      .notify-button {
        padding: 10px 20px;
        background-color: #bc3b59; // Adjust the color as needed
        color: white;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s;
        &:hover {
          background-color: #9b2847;
        }
      }
    }
  }
  &:hover {
    .inside {
      opacity: 1;
      .icon {
        opacity: 1;
      }
    }
  }
  .container {
    width: 100%;
    height: 100%;
    .top {
      height: 73%;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover; // or 'contain' depending on your preference
      }
    }
    .bottom {
      width: 200%;
      height: 27%;
      transition: transform 0.5s;
      &.clicked {
        transform: translateX(-50%);
      }
      h1 {
        margin: 0;
        padding: 0;
      }
      p {
        margin: 0;
        padding: 0;
      }
      .left {
        height: 100%;
        width: 50%;
        background: #f4f4f4;
        position: relative;
        float: left;
        .details {
          padding: 10px;
          padding-top: 20px;
          float: left;
          width: calc(70% - 20px);
          margin-left: 13px;
        }
        .buy {
          float: right;
          cursor: pointer;
          width: calc(30% - 2px);
          height: 100%;
          background: #f1f1f100;
          transition: background 0.5s;
          border-left: solid thin rgba(0, 0, 0, 0.1);
          i {
            font-size: 30px;
            padding: 24px;
            color: #254053;
            padding-top: 52px;
            transition: transform 0.5s;
          }
          &:hover {
            background: #a6cdde;
          }
          &:hover i {
            transform: translateY(5px);
            color: #00394b;
          }
        }
      }
      .right {
        width: 50%;
        background: #a6cdde;
        color: white;
        float: right;
        height: 200%;
        overflow: hidden;
        .details {
          padding: 20px;
          float: right;
          width: calc(70% - 20px);
        }
        .done {
          width: calc(30% - 2px);
          float: left;
          transition: transform 0.5s;
          border-right: solid thin rgba(255, 255, 255, 0.3);
          height: 50%;
          i {
            font-size: 30px;
            padding: 30px;
            color: white;
          }
        }
        .remove {
          width: calc(30% - 1px);
          clear: both;
          border-right: solid thin rgba(255, 255, 255, 0.3);
          height: 50%;
          background: #bc3b59;
          transition: transform 0.5s, background 0.5s;
          &:hover {
            background: #9b2847;
          }
          &:hover i {
            transform: translateY(5px);
          }
          i {
            transition: transform 0.5s;
            font-size: 30px;
            padding: 30px;
            color: white;
          }
        }
        &:hover {
          .remove,
          .done {
            transform: translateY(-100%);
          }
        }
      }
    }
  }

  .inside {
    z-index: 9;
    cursor: pointer;
    background: #92879b;
    border-color: #92879b;
    width: 140px;
    height: 140px;
    position: absolute;
    top: -70px;
    right: -70px;
    border-radius: 0px 0px 200px 200px;
    transition: all 0.5s, border-radius 2s, top 1s;
    opacity: 0;
    overflow: hidden;
    .icon {
      position: absolute;
      right: 85px;
      top: 85px;
      color: white;
      opacity: 0;
      transition: opacity 0.5s;
    }
  }
}
@media screen and (max-width: 800px) {
  .wrapper {
    width: 155px !important;
    height: 331px !important;
  }
  .prodname {
    font-size: 12px !important;
  }
  .container{
    .top{
      height:65% !important
    }
    .bottom{
      height: 35% !important;
    }
  }
  .details {
    padding: 0px !important;
    padding-top: 20px !important;
    p {
      font-size: 1rem !important;
    }
  }
  .buy {
    i {
      padding: 46px 0px 0px 10px !important;
      font-size: 25px !important;
    }
  }
}
@media screen and (width: 1400px){
  .wrapper{
    width: 100px !important;
  }
  .container{
    .bottom{
      height: 39% !important;
    } .top{
      height: 71% !important;
    }
  }
}

@media screen and (max-width: 1500px)and (min-width: 1001px) {
  .wrapper {
    width: calc(270px - 0.2 * (1500px - 100vw)) !important;
    max-width: 275px !important;
    height: calc(480px - 0.2 * (1500px - 100vw)) !important;
    max-height: 480px !important;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1276px){
  .container{
    .bottom{
      height: 39% !important;
    } .top{
      height: 71% !important;
    }
  }
}
@media screen and (max-width: 1275px)and (min-width: 1101px) {
  .container{
    .bottom{
      height: 35% !important;
    } .top{
      height: 65% !important;
    }
  }
}
@media screen and (max-width: 1100px)and (min-width: 1001px) {
  .container{
    .bottom{
      height: 37% !important;
    } .top{
      height: 63% !important;
    }
  }
}
@media screen and (max-width: 1000px) and (min-width: 801px){
  .wrapper {
    width: calc(294px - 0.1 * (1000px - 100vw)) !important;
    max-width: 294px !important;
    height: calc(550px - 0.1 * (1000px - 100vw)) !important;
    max-height: 550px !important;
  }
}