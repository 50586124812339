// HomePage.module.scss
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.container {
  max-width: 1200px;
  margin: 0 auto;
}

// ... (existing styles)

// ... (existing styles)

.hero {
  background-color: #004080; /* Adjusted background color to complement #0B1930 */
  padding: 120px 20px;
  text-align: center;
  color: #fff;

  .companyName {
    font-family: 'Poppins', sans-serif;
    font-size: 3.5rem;
    margin-bottom: 20px;
    animation: fadeInUp 1s ease;
    color: whitesmoke;
  }

  .welcomeMessage {
    max-width: 600px;
    margin: 0 auto;
    animation: fadeInUp 1s ease;

    p {
      font-size: 1.5rem;
      line-height: 1.6;
      margin: 0 auto;
      color: white;
    }
  }

  .btn {
    display: inline-block;
    background-color: #ffcc00; /* Contrasting button background color */
    color: #004080; /* Complementary text color for better readability */
    padding: 15px 30px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    animation: fadeInUp 1s ease;

    &:hover {
      background-color: #e6b800; /* Darker contrasting color on hover */
    }
  }
}

// ... (existing styles)


// ... (existing styles)


// ... (existing styles)

.featuredProducts {
  background-color: #f4f4f4;
  padding: 80px 20px;
  text-align: center;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    color: #333;
    margin-bottom: 30px;
    animation: fadeInUp 1s ease;
  }

  .productCarousel {
    /* Ensure proper spacing around the carousel */
    margin: 0 auto;
    max-width: 1200px;

    /* Apply a subtle box shadow for a clean separation */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .productCard {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      border-radius: 10px 10px 0 0;
    }

    div {
      padding: 20px;
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: 10px;
      color: #333;
    }

    p {
      font-size: 1.2rem;
      color: #555;
      margin-bottom: 15px;
    }

    a {
      display: inline-block;
      background-color: #007bff;
      color: #fff;
      padding: 10px 20px;
      text-decoration: none;
      border-radius: 5px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

// ... (existing styles)

//test


//tesr
.videos {
  padding: 50px;

  h2 {
    text-align: center;
    margin-bottom: 40px;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    animation: fadeInUp 1s ease;
  }

  .videoCarousel {
    overflow-x: auto;
    white-space: nowrap;
    animation: fadeIn 1s ease;
    &::-webkit-scrollbar {
      width: 0; /* Set width to 0 to hide scrollbar */
    }

    .videoCard {
      display: inline-block;
      margin-right: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      animation: slideInRight 1s forwards;

      iframe {
        width: 400px;
        height: 225px;
        border-radius: 10px 10px 0 0;
      }

      .videoDescription {
        background-color: #fff;
        padding: 20px;
        border-radius: 0 0 10px 10px;

        h3 {
          font-size: 1.2rem;
          margin-bottom: 10px;
        }

        p {
          font-size: 1rem;
        }
      }
    }
  }
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
//abiut us 
// HomePage.module.scss

// ... (existing styles)
// ... (existing styles)

// ... (existing styles)

.aboutUs {
  background-color: #f7f7f7; /* Light gray background */
  padding: 80px 20px;
  text-align: center;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    color: #333; /* Dark gray for heading */
    margin-bottom: 30px;
    animation: fadeInUp 1s ease;
  }

  .aboutUsContent {
    max-width: 800px;
    margin: 0 auto;

    p {
      font-size: 1.4rem;
      line-height: 1.8;
      color: #555; /* Slightly darker text color */
      margin-bottom: 20px;
    }

    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
    border-radius: 15px; /* Rounded corners for a modern touch */
    overflow: hidden;

    background-color: #fff; /* White background for content */
    padding: 30px;

    border-bottom: 4px solid #ddd; /* Softer border color */

    /* Add a subtle texture for a professional touch */
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.05) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.05) 50%, rgba(0, 0, 0, 0.05) 75%, transparent 75%, transparent);
    color: #333; /* Dark gray text color for better contrast on white background */
  }
}


// ... (existing styles)

// ... (existing styles)

// ... (existing styles)
//about us 
//brand
// HomePage.module.scss

// ... (existing styles)

// HomePage.module.scss

// ... (existing styles)

.shopByBrand {
  background-color: #f4f4f4;
  padding: 50px;

  h2 {
    text-align: center;
    margin-bottom: 40px;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    color: #333;
  }

  .brandList {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .brandCard {
    text-align: center;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.2rem;
    }
  }
}

// ... (existing styles)


// ... (existing styles)

