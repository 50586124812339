.admin-panel {
  font-family: 'Poppins', sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }

  h4 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 1rem;
  }

  button {
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  button.enable {
    background-color: #4CAF50;
    color: white;
  }

  button.disable {
    background-color: #FF5733;
    color: white;
  }

  button.delete {
    background-color: #DC3545;
    color: white;
  }

  button.other {
    background-color: green;
    color: white;
  }

  button.add {
    background-color: rgb(96, 255, 247);
    color: black;
  }

  button.addSec {
    background-color: rgb(169, 183, 182);
    color: black;
  }

  button:hover {
    opacity: 0.8;
  }

  .fieldName {
    font-size: 15px;
    padding-right: 10px;
  }

  input[type="text"],
  input[type="number"],
  input[type="file"],
  input[type="email"],
  select {
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
    padding: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;

    &:focus {
      border-color: #4CAF50;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  select {
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23343a40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><path d="M4 6l-3.75 3.75 1.5 1.5 3.75-3.75 3.75 3.75 1.5-1.5z"/></svg>');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 12px);
    background-position-y: 50%;
    background-size: 8px;
  }

  .fieldContainer {
    border-radius: 25px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    background-color: antiquewhite;
  }
  
  .fieldContent {
    flex-grow: 1;
    padding: auto;
  }
  
  .deleteContainer {
    width: 80px;
  }
}

.EditFieldModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.modalContent {
  padding: 20px;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  label {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
    display: block;
  }

  input[type="text"],
  input[type="number"],
  input[type="file"],
  input[type="email"],
  select {
    font-size: 1.6rem;
    padding: 0.8rem;
    margin-bottom: 1rem;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #4CAF50;
    }
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  select {
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23343a40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><path d="M4 6l-3.75 3.75 1.5 1.5 3.75-3.75 3.75 3.75 1.5-1.5z"/></svg>');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 12px);
    background-position-y: 50%;
    background-size: 8px;
  }

  button {
    font-size: 1.6rem;
    padding: 0.8rem 1.5rem;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  button:hover {
    opacity: 0.8;
  }

  button.add {
    background-color: rgb(96, 255, 247);
    color: black;
  }

  button.add:hover {
    background-color: #6DDAF0;
  }

  button.cancel {
    background-color: #DC3545;
    color: white;
  }

  button.cancel:hover {
    background-color: #FF6B81;
  }
}
