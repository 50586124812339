.product {
  display: flex;
  position: relative;

  .filter {
    width: 15%;
    // border: 2px solid var(--dark-blue);
    border-right: 1px solid #ddd;
    transition: all 0.3s;
  }

  .content {
    width: 85%;
    padding-left: 5px;
    position: relative;
    .icon {
      display: none;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;

      & > * {
        padding-left: 5px;
      }
    }
  }
  @media screen and (max-width: 800px) {
    .icon {
      top: -29px !important;
      display: flex;
    }
    .whatsappButton {
      bottom: 6rem !important;
    }
    .filter {
      width: 50%;
      background-color: #fff;
      border: 2px solid var(--dark-blue);
      position: absolute;
      left: -200%;
      height: 100%;
      padding: 1rem;
      z-index: 99;
    }
    .show {
      left: 0;
      height: auto;
    }
    .content {
      width: 100%;
      .icon {
        display: flex;
      }
    }
  }
}
.whatsappButton {
  position: fixed;
  bottom: 2rem;
  right: 0;
  width: 58px;
  height: 61px;
  z-index: 999999;
  background: green;
  border-radius: 100% 0 0 100%;
  padding: 0.5rem;
  box-shadow: 0px 2px 5px 2px #00000036;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background: #039c039c; /* Change the hover background color as needed */
  }

  svg {
    margin-right: -1rem;
  }
}
.search {
  margin: 5px 0;
  position: relative;
  flex: 1;
  width: 100px;

  .icon {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
  }

  input[type="text"] {
    display: block;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 1rem;
    padding-left: 3rem;
    margin: 1rem auto;
    width: 100%;
    border: 1px solid #777;
    border-radius: 3px;
    outline: none;
  }
}
.instagramButton {
  position: fixed;
  bottom: 100px; /* Adjusted to match WhatsApp button position */
  right: 0px;
  width: 58px;
  height: 61px;
  z-index: 999999;
  background:radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);; /* Instagram's brand color */
  border-radius: 100% 0 0 100%;
  padding: 0.5rem;
  box-shadow: 0px 2px 5px 2px #00000036;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background: #5851db; /* Darker shade on hover, you can customize this */
  }

  svg {
    margin-right: -1rem;
  }
}
@media (max-width: 768px) {
  .whatsappButton{
    width: 48px;
    height: 20;
    padding: 0.3rem;
    margin-right: 0px;

  }
  .instagramButton {
    width: 48px;
    height: 60px;
    padding: 0.3rem;
    margin-bottom: 25px;
    margin-right: 0px; /* Add spacing between buttons */
  }

  .whatsappButton svg{
    margin-right: -0.8rem;
  }
  .instagramButton svg {
    margin-right: -0.8rem;
  }
}
