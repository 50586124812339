.chartCard {
    width: 100%;
    max-width: 500px;
    padding: 1rem; 
    border: 1px solid black;
    margin: 0 auto; 
    box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.1); 
  }

  .chartContainer {
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    height: 300px; 
  }

  h3 {
    padding: 20px;
    text-align: center; 
  }