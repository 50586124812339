.container {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
}

.wrapper {
  display: flex;
}
.itemDetails {
  display: flex;
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  align-items: center;
}
.linkstyle {
  color: #007185;
  font-size: 14px;
  cursor: pointer;
}

.linkstyle:hover {
  text-decoration: underline;
  color: orangered;
}
.itemInfo {
  margin-left: 10px; // Adjust the margin as needed
  margin-right: 80px; // Adjust the margin as needed
}
.prodImage {
  margin-right: 30px;
  width: 75px;
}

.prodname {
  position: relative;
  font-size: 18px;
  line-height: 32px;
  width: 340px;
  font-weight: 500;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 350px; /* Set the desired width of the border */
    height: 1px; /* Set the thickness of the border */
    background-color: #ccc;
  }
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.priceSym {
  top: -0.5em;
  font-size: 13px;
  position: relative;
}
.priceSym1 {
  top: -0.3em;
  font-size: 13px;
  position: relative;
}
.priceData {
  font-size: 22px;
}
.size {
  font-size: 17px;
}
.priceData1 {
  font-size: 19px;
}
.tax {
  padding-left: 0.9rem;
  margin-top: -8px;
}
.tax1 {
  padding-left: 1rem;
  margin-top: -8px;
  margin-bottom: 30px;
}
.leftContent {
  width: 75%;
  padding: 0px 50px;
  h2 {
    margin-bottom: 40px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    tr:first-child {
      margin-top: 20px;
      border-top: 1px solid #ddd; /* Add top border style here */
    }
    td:first-child {
      border-bottom: 1px solid #ccc;
    }
    th,
    td {
      border-bottom: 1px solid #ccc;
      padding: 8px;
    }
    td:first-child {
      padding-left: 40px;
    }

    th {
      background-color: #f2f2f2;
    }
  }

  .summary {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: start;

    .card {
      padding: 1rem;

      .text {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          color: var(--color-danger);
        }
      }

      button {
        margin-top: 5px;
      }
    }
  }
}

.rightContent {
  width: 25%;
  margin-top: 80px;
  margin-right: 30px;
}

.lineSeparator {
  width: 2px;
  background-color: #ccc;
  height: 100%;
}

.count {
  display: flex;
  align-items: center;

  button {
    border: 1px solid var(--darkblue);
  }

  & > * {
    margin-right: 1rem;
  }
}
.footer {
  margin-top: 20px;
  margin-left: 15px;

  button {
    padding: 10px 15px;
    font-size: 1rem;
    cursor: pointer;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #c82333;
    }
  }
}
.btn {
  width: 100%;
  button {
    padding: 8px 12px;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #4caf50;
      color: white;
    }
  }
}
.card {
  border: 1px solid #ddd;
  padding: 0px 30px;
  padding-bottom: 30px;
  overflow: hidden;
  transition: transform 0.3s ease;
  box-shadow: none;

  .text {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin-bottom: 8px;
      font-size: 1.5rem;
    }
  }

  button {
    align-items: center;
    padding: 8px 12px;
    width: 100%;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #4caf50;
      color: white;
    }
  }
}
@media screen and (max-width: 800px) {
  .wrapper {
    flex-direction: column;
    padding: 15px;
  }
  .container {
  }
  .leftContent {
    width: 100%;
    padding: 0px;
    table {
      tr {
        width: 100%;
      }
      td {
        padding: 0;
      }
      td:first-child {
        padding-left: 5px;
      }
      svg {
        height: 12px;
      }
    }
  }

  .rightContent {
    width: 100%;
    margin-top: 20px;
    margin-right: 0;
  }

  .itemInfo {
    margin-left: 0; /* Adjust the margin as needed */
    margin-right: 0; /* Adjust the margin as needed */
  }

  .prodname {
    font-size: 12px;
    width: 100%;
    line-height: 1;
    padding: 0;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 1px; /* Set the desired width of the border */
      height: 1px; /* Set the thickness of the border */
      background-color: #ccc;
    } /* Set the width to 100% for small screens */
  }
  .priceData {
    font-size: 14px;
  }
  .priceSym {
    font-size: 9px;
  }
  .prodImage {
    margin-right: 0;
    width: 80px;
    height: 80px; /* Set a fixed height for the image */
    object-fit: cover;
    margin-right: 20px;
    margin-left: 20px;
  }

  .summary {
    margin-top: 1rem;
  }
  .itemDetails {
    margin-left: 0px;
  }
  .prodImage {
    width: 75px;
    height: 75px;
  }
  .size {
    font-size: 10px;
  }
  .--btn{
    font-size: 1rem !important;
  }
}
