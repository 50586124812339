.container {
  margin: 0 auto;
  max-width: 1200px;
}

.wishlist {
  margin-top: 20px;
  padding: 20px 0;
}

.wishlistGrid {
  display: grid;
  grid-template-columns: 0.65fr; /* Make each card take up the full width */
  gap: 10px;
  justify-content: center;
}
// Add this to your styles
.disabled {
  cursor: not-allowed;
  opacity: 0.6; // Adjust the opacity to your preference
}

.prodname {
  position: relative;
  font-size: 18px;
  line-height: 32px;
  width: 470px;
  font-weight: 500;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 400px; /* Set the desired width of the border */
    height: 1px; /* Set the thickness of the border */
    background-color: #ccc;
  }
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.tax {
  padding-left: 1.2rem;
  margin-top: -8px;
}
.priceSym {
  top: -0.5em;
  font-size: 13px;
  position: relative;
}
.priceData {
  font-size: 22px;
}
.productInfo2 {
  padding: 1rem;
  margin-top: 1rem;
  box-shadow: none;
  border: 1px solid #ccc;
  margin-right: 30px;
}
.linkstyle {
  color: #007185;
  font-size: 14px;
  cursor: pointer;
}

.linkstyle:hover {
  text-decoration: underline;
  color: orangered;
}
.empty {
  margin-top: 40px;
  margin-left: 260px;
}
.footer {
  margin-top: 40px;
  margin-left: 260px;

  button {
    padding: 10px 15px;
    font-size: 1rem;
    cursor: pointer;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #c82333;
    }
  }
}
.wishlistCard {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  .productInfo {
    display: flex;
    align-items: center;

    img {
      width: 100px;
      height: 100px; /* Set a fixed height for the image */
      border-radius: 8px;
      object-fit: cover;
    }

    .details {
      flex-grow: 1;

      h3 {
        margin-bottom: 8px;
        font-size: 1.2rem;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column; /* Place actions below the image and details */
    justify-content: space-between;
    align-items: flex-end; /* Align actions to the end of the card */
    padding: 10px;

    button {
      padding: 8px 12px;
      font-size: 0.9rem;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #4caf50;
        color: white;
      }
    }

    svg {
      cursor: pointer;
      transition: color 0.3s ease;

      &:hover {
        color: red;
      }
    }
  }
}
.heading {
  text-align: left; /* Center align the heading */
  margin-left: 260px; /* Add some bottom margin for spacing */
}
.productInfo {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 40px;

  img {
    width: 100px;
    margin-right: 60px;
    border-radius: 8px;
  }
}

.details {
  flex-grow: 1;

  h3 {
    margin-bottom: 8px;
    font-size: 1.2rem;
  }
}

.total {
  margin-top: 8px;
  font-size: 0.9rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  button {
    padding: 8px 12px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #4caf50;
      color: white;
    }
  }

  svg {
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: red;
    }
  }
}

.summary {
  margin-top: 20px;

  button {
    padding: 10px 15px;
    font-size: 1rem;
    cursor: pointer;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #c82333;
    }
  }
}
@media screen and (max-width: 800px) {
  .wishlistGrid {
    grid-template-columns: 1fr; /* Change to a single column layout for small screens */
  }
  .priceSym {
    font-size: 8px;
  }
  .priceData {
    font-size: 13px;
  }
  .footer{
    button{
      font-size: 0.8rem;
    }
    margin-bottom: 30px;
  }
  .price{
    margin-left: 8px;
  }
  .prodname {
    width: 100%;
    font-size: 13px;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0px; /* Set the desired width of the border */
      height: 1px; /* Set the thickness of the border */
      background-color: #ccc;
    } /* Set the width to 100% for small screens */
  }
  .temp {
    margin-left: 0 !important;
  }
  .tax {
    font-size: 9px;
    padding-left: 1.3rem;
    margin-top: -5px;
    margin-bottom: 10px;
  }
  .productInfo2 {
    margin-right: 0;
    padding: 0; /* Remove the right margin for small screens */
  }
  .productInfo {
    padding: 0;
    svg {
      margin-left: 15px;
    }
    img {
      width: 80px;
      height: 80px; /* Set a fixed height for the image */
      object-fit: cover; /* Maintain aspect ratio and cover the container */
      border-radius: 8px;
      margin-right: 20px;
      margin-left: 20px;
    }
    .actions {
      button {
        font-size: 0.8rem;
      }
    }
  }

  .empty,
  .footer,
  .heading,
  .productInfo {
    margin-left: 0; /* Remove the left margin for small screens */
  }
}
.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
}

.modalOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999; /* Ensure overlay is above other content */
}

.selectLabel {
  font-size: 16px;
  margin-bottom: 10px;
}

.selectWrapper {
  position: relative;
}

.selectWrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #888;
}

.select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  font-size: 14px;
  cursor: pointer;
}

.select option {
  font-size: 14px;
}

.modalActions {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  button {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #007185;
      color: white;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
